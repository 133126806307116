import styled from 'styled-components'

export const NewsletterFormWrapper = styled.form`
	& .MuiFormControl-root {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		gap: 8px;

		@media (min-width: 1200px) {
			height: 40px;
			flex-direction: row;
		}
	}
`

export const NewsletterTitle = styled.div`
	& .MuiTypography-root {
		margin: 8px 0px 8px;
		font-size: 14px;
		line-height: 1.43;
		font-weight: 600;

		&:first-letter {
			text-transform: uppercase;
		}
	}
`

export const NewsletterDescription = styled.div`
	& .MuiTypography-root {
		margin: 0px 0px 16px;
		font-weight: 400;
		font-size: 14px;
		line-height: 1.43;
		color: rgb(148, 166, 184);

		&:first-letter {
			text-transform: uppercase;
		}
	}
`
