import styled from 'styled-components'
import { FormControlLabel, FormControlLabelProps } from '@mui/material'
import theme from '../../theme'

export const StyledFormControlLabel = styled(
	FormControlLabel,
)<FormControlLabelProps>`
    .MuiformControlLabel-label {
        margin: 0;
        color: ${theme.palette.common.black}

        &:first-letter {
            text-transform: uppercase;
        }
    }
`
