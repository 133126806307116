import styled from "styled-components";
import { Accordion, AccordionProps } from "@mui/material";

export const StyledAccordion = styled(Accordion)<AccordionProps>`
  &.MuiPaper-root.MuiAccordion-root {
    background: #222;
    color: #ccc;

    & .MuiAccordionSummary-expandIconWrapper {
      & .MuiSvgIcon-root {
        color: #ccc;
      }
    }

    &:hover {
        background: #333;
      color: #fff;

      & .MuiAccordionSummary-expandIconWrapper {
        & .MuiSvgIcon-root {
          color: #fff;
        }
      }
    }

    &.Mui-expanded {
      color: #f00;
      background: #ddd;

      & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
        & .MuiSvgIcon-root {
          color: #f00;
        }
      }

      /* Nested accordion specific styles */
      & .MuiAccordion-root {
        color: #bbb;
        background: #fff;

        & .MuiSvgIcon-root {
            color: #bbb;
        }

        &:hover {
            background: #eee;
            color: #000;

            & .MuiSvgIcon-root {
                color: #000;
            }
        }

        &.Mui-expanded {
          color: #fff;
          background: #222;

          & .MuiSvgIcon-root {
            color: #fff;
          }

          & .MuiCollapse-wrapper {
            background: #444;
          }
        }
      }
    }
  }
`;

export const StyledInnerAccordion = styled(Accordion)<AccordionProps>`
  &.MuiPaper-root.MuiAccordion-root {
    color: #ccc;
    background: #fff important!;

    & > .MuiAccordionSummary-expandIconWrapper {
      & .MuiSvgIcon-root {
        color: #ccc;
      }
    }

    &:hover {
      color: #000;

      & > .MuiAccordionSummary-expandIconWrapper {
        & .MuiSvgIcon-root {
          color: #000;
        }
      }
    }

    &.Mui-expanded {
      color: #fff;
      background: #222;

      & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
        & .MuiSvgIcon-root {
          color: #fff;
        }
      }
    }
  }
`;
