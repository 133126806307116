import { TStateCounter, TCounterActionTypes } from '../../../schemas/counter_d'
import TYPES from './types'

const initialState: TStateCounter = {
	counter: 0,
}

const sampleReducer = (
	state: TStateCounter = initialState,
	action: TCounterActionTypes,
): TStateCounter => {
	switch (action.type) {
		case TYPES.INCREMENT:
			return {
				...state,
				counter: state.counter + 1,
			}
		case TYPES.DECREMENT:
			return {
				...state,
				counter: state.counter - 1,
			}
		case TYPES.RESET:
			return {
				...state,
				counter: 0,
			}
		default:
			return state
	}
}

export default sampleReducer
