import { PaletteOptions } from '@mui/material/styles'

const gray50 = '#f6f7f8'

export const AZURE_PALETTE = {
	GREEN: '#4eb439',
	RED: '#e61b33',
}

export const GRAPH_PALETE = {
	GREEN: '#67B4B7',
	PURPLE: '#6825B4',
	L_GREEN: '#89D0D3',
	L_PURPLE: '#C59CF2',
}

export const customPalette = {
	primary: {
		main: '#FF0000',
		contrastText: '#fff',
	},
	spacer: {
		main: '#c8d6eb',
	},
	avatar: {
		main: '#f6f8fb',
	},
	secondary: {
		main: '#222222',
		light: '#444444',
		dark: '#000000',
		contrastText: '#fff',
		'50': '#F3F7FF',
		100: '#E7EFFE',
		200: '#D4E2FE',
		300: '#B2CBFC',
		400: '#93B6FA',
		500: '#79A3F7',
		600: '#6392F2',
		700: '#2A55AC',
		800: '#294A8B',
		900: '#141E33',
	},
	success: {
		main: '#1e8509',
		light: '#1E850933',
		dark: '#144E1A',
		50: '#F0FDF2',
		100: '#E2FCE5',
		200: '#CAFACF',
		300: '#B2F5B8',
		400: '#7EF38A',
		500: '#61EE6F',
		600: '#4AE559',
		700: '#188923',
		800: '#176A1F',
		900: '#0F3313',
	},
	error: {
		main: '#E61B34',
		light: '#f8bbc2',
		dark: '#530A0A',
		contrastText: '#fff',
		50: '#FFF3F3',
		100: '#FFE7E7',
		200: '#FECFCF',
		300: '#FCA1A1',
		400: '#FA7979',
		500: '#F55959',
		600: '#EE4040',
		700: '#961515',
		800: '#720E0E',
		900: '#330606',
	},
	background: {
		default: '#fff',
		secondary: gray50,
		details: '#f7faff',
	},
	titles: {
		default: '#1f62af',
	},
	grey: {
		50: gray50,
		100: '#f1f2f4',
		200: '#e8ebed',
		300: '#ccd1d6',
		400: '#a2abb5',
		500: '#7b8a9a',
		600: '#535b64',
		700: '#464e54',
		800: '#2d3238',
		900: '#292e33',
		A100: '#1f2327',
		A200: '#171a1c',
		A300: '#111214',
		A400: '#f3f3f3',
		A700: '#3e444b',
	},
	greyCustom: {
		main: '#5C6B82',
		light: '#7D8EA7',
		dark: '#272F3A',
	},
	greyAlpha: {
		main: 'rgba(25, 39, 58, 0.74)',
		light: 'rgba(24, 48, 72, 0.56)',
		dark: 'rgba(10, 18, 25, 0.88)',
		50: 'rgba(31, 82, 158, 0.02)',
		100: 'rgba(0, 43, 94, 0.04)',
		200: 'rgba(2, 47, 82, 0.06)',
		300: 'rgba(16, 40, 72, 0.09)',
		400: 'rgba(4, 41, 68, 0.13)',
		500: 'rgba(16, 48, 84, 0.20)',
		600: 'rgba(13, 38, 64, 0.40)',
		700: 'rgba(29, 41, 54, 0.80)',
		800: 'rgba(26, 35, 45, 0.86)',
		900: 'rgba(10, 20, 28, 0.93)',
	},
	raspberry: {
		main: '#C03060',
		light: '#E4628D ',
		dark: '#671E36',
		50: '#FFFAFC ',
		100: '#FEF5F8',
		200: '#FDEDF2',
		300: '#FDE3EB',
		400: '#FBD5E2',
		500: '#F8CEDC',
		600: '#EC8DAD',
		700: '#9D3457 ',
		800: '#912B4D',
		900: '#450C1F',
	},
	magenta: {
		main: '#B12F86',
		light: '#DE5EB3',
		dark: '#671E4E',
		50: '#FFFAFD  ',
		100: '#FEF5FB',
		200: '#FDEDF8',
		300: '#FDE3F4',
		400: '#FBD5EE',
		500: '#F7CAE8',
		600: '#EC89CB',
		700: '#953274 ',
		800: '#892969',
		900: '#410B2F',
	},
	purple: {
		main: '#9939AC',
		light: '#C466DB',
		dark: '#5B1E67',
		50: '#FEFAFF',
		100: '#FDF5FF',
		200: '#FAEDFD',
		300: '#F8E3FD',
		400: '#F5D5FB',
		500: '#F4CAF7',
		600: '#D98DEC',
		700: '#853295',
		800: '#792989',
		900: '#410B2F',
	},
	grape: {
		main: '#8040BF ',
		light: '#AD71EA',
		dark: '#4C2277',
		50: '#FCFAFF',
		100: '#FAF5FF',
		200: '#F6F0FF',
		300: '#F2E5FF',
		400: '#EDDBFF',
		500: '#E5D0FB',
		600: '#C494F4',
		700: '#7339AC',
		800: '#662F9D',
		900: '#2E0E4E',
	},
	violet: {
		main: '#6B73B5',
		light: '#8A92E0',
		dark: '#303459',
		50: '#F5F6FF',
		100: '#EBEDFF',
		200: '#D9DDFF',
		300: '#C8CEFF',
		400: '#B9C0FD',
		500: '#AAB2F9',
		600: '#9CA4F1',
		700: '#555C95',
		800: '#414674',
		900: '#20233D',
	},
	cyan: {
		main: '#0870BA',
		light: '#5699DC',
		dark: '#0C456E',
		50: '#FAFDFF',
		100: '#F0F9FF',
		200: '#EBF5FF',
		300: '#E0F1FF',
		400: '#CCE7FF',
		500: '#C2E3FF',
		600: '#79BCF6',
		700: '#1B679D',
		800: '#165B8D',
		900: '#002742',
	},
	teal: {
		main: '#077597',
		light: '#4F9EBA',
		dark: '#0A495C',
		50: '#FAFEFF',
		100: '#F0FBFF',
		200: '#E8F7FC',
		300: '#E0F3FA',
		400: '#C5ECF7',
		500: '#B8E8F5',
		600: '#7AC0DC',
		700: '#196C85',
		800: '#196076',
		900: '#012A37',
	},
	aquamarine: {
		main: '#00B4B1',
		light: '#03E0DC',
		dark: '#005150',
		accordion: '#5C87F2',
		50: '#E4FFFF',
		100: '#C9FFFF',
		200: '#96FFFF',
		300: '#68FFFF',
		400: '#40FFFE',
		500: '#20FFFA',
		600: '#0EF4EF',
		700: '#00928F',
		800: '#006F6D',
		900: '#003332',
	},
	emerald: {
		main: '#347434',
		light: '#55A555',
		dark: '#135315',
		50: '#FBFEFB',
		100: '#F4FBF4',
		200: '#E6F9E6',
		300: '#DFF6DF',
		400: '#CFF2CF',
		500: '#C6ECC6',
		600: '#87C987',
		700: '#2F6F2F',
		800: '#246626',
		900: '#0A2E0B',
	},
	orange: {
		main: '#E98C00',
		light: '#FF9900',
		dark: '#704300',
		50: '#FFFBF1',
		100: '#FFF6E2',
		200: '#FFEDC7',
		300: '#FFD993',
		400: '#FFC664',
		500: '#FFB63B',
		600: '#FFA71A',
		700: '#BA7000',
		800: '#955900',
		900: '#331F00',
	},
	severity: {
		LOW: '#FBC02D',
		MEDIUM: ' #F16F34',
		HIGH: '#E61B34',
	},
	black: {
		main: '#000000',
	},
} as PaletteOptions
