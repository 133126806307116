import styled from 'styled-components'
import { Button, ButtonGroup, ButtonProps, ButtonGroupProps } from '@mui/material'

export const StyledButton = styled(Button)<ButtonProps>`
	&.MuiButtonBase-root:not(.MuiCheckbox-root) {
		position: relative;
		width: fit-content;
		border-radius: 10px;
		align-self: center;

		&:hover {
			text-decoration: none;
			background-color: #ef0000;
			box-shadow:
				0px 2px 4px -1px rgba(0, 0, 0, 0.2),
				0px 4px 5px 0px rgba(0, 0, 0, 0.14),
				0px 1px 10px 0px rgba(0, 0, 0, 0.12);
		}

		&.Mui-disabled {
			background-color: rgba(255, 0, 0, 0.2);
		}

		&.reset {
			background-color: #444;

			&:hover {
				text-decoration: none;
				background-color: #000;
				box-shadow:
					0px 2px 4px -1px rgba(0, 0, 0, 0.2),
					0px 4px 5px 0px rgba(0, 0, 0, 0.14),
					0px 1px 10px 0px rgba(0, 0, 0, 0.12);
			}
		}
	}
`
export const StyledMobileMenuButton = styled(Button)<ButtonProps>`
	&.MuiButtonBase-root {
		&.MuiButton-root {
			position: relative;
			width: fit-content;
			border-radius: 10px;
			align-self: center;
			color: #000;
			border: 0;
		}
	}
`

export const StyledButtonGroup = styled(ButtonGroup)<ButtonGroupProps>`
	&.MuiButtonGroup-root {
		column-gap: 10px;
		box-shadow: none;
	}
`