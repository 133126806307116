import React from 'react'
import useCustomLocation from '../hooks/useCustomLocation'
import { useTranslation } from 'react-i18next'

import ContactForm from '../components/contacts/components/ContactForm'

import Container from '@mui/material/Container'

import CircularProgress from '../components/circular-progress/CirculaProgress'

const Contact: React.FC = () => {
	const { t } = useTranslation()
	const path = useCustomLocation()

	return (
		<Container>
			<h1 className="pageTitle">{t(`pages.${path}.title`)}</h1>
			<div style={{ display: 'flex', gap: '20px' }}>
				<CircularProgress degree={84} color="#ff2972" title="HTML" />
				<CircularProgress degree={58} color="#fee800" title="CSS" />
				<CircularProgress degree={95} color="#04fc43" title="JavaScript" />
			</div>
			<ContactForm />
		</Container>
	)
}

export default Contact
