import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function useCustomLocation() {
	const [path, setPath] = useState('')
	const location = useLocation()

	useEffect(() => {
		const pathlength = location.pathname.split('/')
		const currentPath: string =
			pathlength.length < 3
				? location.pathname.replace('/', '').length === 0
					? 'home'
					: location.pathname.replace('/', '')
				: pathlength[1]
		setPath(currentPath)
	}, [location.pathname])

	return path
}

export default useCustomLocation
