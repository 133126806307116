import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectors as navigationSelectors } from '../../redux/components/navigation'
import { capitalize } from '../../utils/capitalize'

import NavbarIcons from '../navigation/NavbarIcons'
import LanguageSelector from '../language-selector/LanguageSelector'

import { StyledMobileMenuButton } from '../../styleguide/Button'
import { NavItem } from '../navigation/style'

import Dialog from '@mui/material/Dialog'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemButton from '@mui/material/ListItemButton'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import Slide from '@mui/material/Slide'
import Box from '@mui/material/Box'
import { TransitionProps } from '@mui/material/transitions'

import {StyledListItemText, StyledAppBarMenuText, StyledListItemButton} from './style'

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="down" ref={ref} {...props} />
})

const MobileMenu = () => {
	const { t } = useTranslation()
	const pages = useSelector(navigationSelectors.getNavigationItems)
	const [open, setOpen] = React.useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<React.Fragment>
			<StyledMobileMenuButton variant="outlined" onClick={handleClickOpen}>
				<MenuIcon />
			</StyledMobileMenuButton>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<StyledAppBarMenuText sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							{capitalize(t(`navigation.menu`))}
						</StyledAppBarMenuText>
					</Toolbar>
				</AppBar>
				<List>
					{pages
						.filter(page => page.displayInNavbar)
						.map((page, index) => (
							<>
								<NavItem key={`${page.navigationItemId}${index}`}>
									<NavLink to={page.link} onClick={handleClose}>
										<StyledListItemButton>
											<ListItemIcon>
												<NavbarIcons icon={page.name} />
											</ListItemIcon>
											<StyledListItemText primary={<Typography >{t(`pages.${page.name}.title`)}</Typography>}/>
										</StyledListItemButton>
									</NavLink>
								</NavItem>
								<Divider />
							</>
						))}
				</List>
				<Box sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
					<Typography variant="body2">
						{capitalize(t(`language.message`))}
					</Typography>
					<LanguageSelector />
				</Box>
			</Dialog>
		</React.Fragment>
	)
}

export default MobileMenu
