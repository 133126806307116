const PREFIX = 'FAQS'
const GET_FAQS = `${PREFIX}.GET_FAQS`
const SET_FAQS = `${PREFIX}.SET_FAQS`


const TYPES = {
	PREFIX,
	GET_FAQS,
	SET_FAQS,

}

export default TYPES
