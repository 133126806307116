import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Spinner from '../spinner/Spinner';
import Signin from './components/Signin';
import Login from './components/Login';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { AuthWrapper, SectionTitle } from './style';

import { StyledButton, StyledButtonGroup } from '../../styleguide/Button';

const Authentication: React.FC = () => {
  const [loginView, setLoginView] = useState(true);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const handleToggleView = () => {
    setLoading(true);
    setTimeout(() => {
      setLoginView(!loginView);
      setLoading(false);
    }, 2000);
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <AuthWrapper>
          <Box>
            <SectionTitle
              src={`./assets/images/auth/${loginView ? 'login' : 'signin'}.png`}
              alt={loginView ? 'Login' : 'Signin'}
            />
          </Box>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} md={6} lg={6}>
              <StyledButtonGroup variant="contained" aria-label="Basic button group">
                <StyledButton
                  variant="contained"
                  color="primary"
                  type="button"
                  disabled={loginView}
                  onClick={handleToggleView}
                >
                  {t(`button.login`)}
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  type="button"
                  disabled={!loginView}
                  onClick={handleToggleView}
                >
                  {t(`button.signin`)}
                </StyledButton>
              </StyledButtonGroup>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              {loginView ? <Login /> : <Signin />}
            </Grid>
          </Grid>
        </AuthWrapper>
      )}
    </>
  );
};

export default Authentication;
