import styled from 'styled-components'
import { ListItemButton, ListItemText, Typography, ListItemButtonProps, ListItemTextProps, TypographyProps } from '@mui/material'

export const StyledListItemText = styled(ListItemText)<ListItemTextProps>`
	&.MuiListItemText-root {
		& p.MuiTypography-root.MuiTypography-body1 {
			font-family: handwrite;
			font-weight: 600;
			font-size: 1.2rem;
			color: #ccc;
		}
	}
`

export const StyledAppBarMenuText = styled(Typography)<TypographyProps>`
	&.MuiTypography-root {
			font-family: handwrite;
			font-weight: 600;
			font-size: 1.2rem;
			color: #fff;
		}
`
export const StyledListItemButton = styled(ListItemButton)<ListItemButtonProps>`
	&.MuiListItemButton-root {
        &.MuiListItemButton-root:hover {
            background-color: rgba(255, 0, 0, 0.1);
        }
    }
`