import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectors as faqsSelectors } from '../../redux/components/faqs';

import { AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { TFaqsSection } from '../../types/faqs';

import { StyledAccordion, StyledInnerAccordion } from '../../styleguide/Accordion';

interface FaqsProps {}

const Faqs: React.FC<FaqsProps> = () => {
  const [expandedMain, setExpandedMain] = useState<string | false>(false);
  const [expandedMinor, setExpandedMinor] = useState<string | false>(false);
  const faqs: TFaqsSection[] = useSelector(faqsSelectors.getFaqs);

  useEffect(() => {
    if (!expandedMain) {
      setExpandedMinor(false);
    }

    return () => {
      setExpandedMinor(false);
    }
  }, [expandedMain]);

  const handleChangeMain =
    (panel: string) => (event: React.SyntheticEvent, expanded: boolean) => {
      setExpandedMain(expanded ? panel : false);
    };

  const handleChangeMinor =
    (parentPanel: string, panel: string) => (event: React.SyntheticEvent, expanded: boolean) => {
      if (expandedMain === parentPanel) {
        setExpandedMinor(expanded ? panel : false);
      }
    };

  return (
    <div>
      {faqs.map((faq) => (
        <StyledAccordion
          key={faq.sectionId}
          expanded={expandedMain === `${faq.sectionId}${faq.sectionTitle}`}
          onChange={handleChangeMain(`${faq.sectionId}${faq.sectionTitle}`)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${faq.sectionId}${faq.sectionTitle}bh-content`}
            id={`${faq.sectionId}${faq.sectionTitle}bh-header`}
          >
            <Typography variant="h6">{faq.sectionTitle}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {faq.subsections.map((sub, subIndex) => (
              <StyledInnerAccordion
                key={subIndex}
                expanded={expandedMinor === `${faq.sectionId}${subIndex}${sub.title}`}
                onChange={handleChangeMinor(`${faq.sectionId}${faq.sectionTitle}`, `${faq.sectionId}${subIndex}${sub.title}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${subIndex}${sub.title}bh-content`}
                  id={`${subIndex}${sub.title}bh-header`}
                >
                  <Typography>{sub.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{sub.content}</Typography>
                </AccordionDetails>
              </StyledInnerAccordion>
            ))}
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </div>
  );
};

export default Faqs;
