import styled from 'styled-components'

export const SpinnerWrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff; // Sostituire con il colore del tuo brand
	z-index: 9999;
`
