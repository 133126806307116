import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { capitalize } from '../../../../utils/capitalize';

export const getLoginSchema = (t: TFunction) => Yup.object().shape({
  email: Yup.string()
    .email(capitalize(t(`form.validation.email`)))
    .required(capitalize(t(`form.validation.required`))),
  password: Yup.string()
    .min(8)
    .matches(/[a-z]/)
    .matches(/[A-Z]/)
    .matches(/[0-9]/)
    .matches(/[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/)
    .required(capitalize(t(`form.validation.required`))),
  remember: Yup.boolean(),
});

export const getSignupSchema = (t: TFunction) => Yup.object().shape({
  username: Yup.string().required(capitalize(t(`form.validation.required`))),
  email: Yup.string()
    .email(capitalize(t(`form.validation.email`)))
    .required(capitalize(t(`form.validation.required`))),
  password: Yup.string()
    .min(8)
    .matches(/[a-z]/)
    .matches(/[A-Z]/)
    .matches(/[0-9]/)
    .matches(/[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/)
    .required(capitalize(t(`form.validation.required`))),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref('password')],
      capitalize(t(`form.validation.confirmPassword`)),
    )
    .required(capitalize(t(`form.validation.required`))),
});
