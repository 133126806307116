import React from 'react'

import Authentication from '../components/auth/Authentication'

const Auth: React.FC = () => {
	return (
		<>
			<Authentication />
		</>
	)
}

export default Auth
