import * as React from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'

const CardComponent: React.FC = () => {
	return (
		<>
			<Card variant="outlined" sx={{ maxWidth: '250px' }}>
				<CardHeader
					title="Standard license"
					subheader="Full resolution 1920x1080 • JPEG"
				/>
				<CardMedia
					component="img"
					image="./assets/images/notebooks/notebook.png"
					alt="complex"
				/>
				<Box>
					<CardContent>
						<Box>
							<Box>
								<Typography gutterBottom variant="subtitle1" component="div">
									Another information is available here and if you want to read
									more clivk the button below
								</Typography>
								<Typography variant="body2" gutterBottom>
									You can buy several book of this collection so we can make
									money at your own face
								</Typography>
								<Typography variant="body2" color="text.secondary">
									ID: 1030114
								</Typography>
							</Box>
							<Box>
								<Typography variant="subtitle1" component="div">
									$19.00
								</Typography>
							</Box>
						</Box>
					</CardContent>
					<CardActions>
						<Typography sx={{ cursor: 'pointer' }} variant="body2">
							Remove
						</Typography>
					</CardActions>
				</Box>
			</Card>
		</>
	)
}

export default CardComponent
