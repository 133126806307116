import {
	TSetDeviceType,
	TSetLanguage,
	TSetWindowWidth,
	TSetLoading,
	TSetMessage,
	TStateUi,
	TUiActionTypes,
} from '../../../schemas/ui_d'
import TYPES from './types'

const initialState: TStateUi = {
	windowWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
	language: 'en',
	deviceType: 'desktop',
	toggleView: true,
	isLoading: false,
	message: {
		body: undefined,
		type: undefined,
	},
}

const uiReducer = (
	state: TStateUi = initialState,
	action: TUiActionTypes,
): TStateUi => {
	switch (action.type) {
		case TYPES.SET_WINDOW_WIDTH:
			return {
				...state,
				windowWidth: (action as TSetWindowWidth).payload,
			}
		case TYPES.SET_LANGUAGE:
			return {
				...state,
				language: (action as TSetLanguage).payload,
			}
		case TYPES.SET_DEVICE_TYPE:
			return {
				...state,
				deviceType: (action as TSetDeviceType).payload,
			}
		case TYPES.TOGGLE_VIEW:
			return {
				...state,
				toggleView: !state.toggleView,
			}
		case TYPES.SET_LOADING:
			return {
				...state,
				isLoading: (action as TSetLoading).payload,
			}
		case TYPES.SET_MESSAGE:
			return {
				...state,
				message: (action as TSetMessage).payload,
			}
		case TYPES.DELETE_MESSAGE:
			return {
				...state,
				message: {
					body: undefined,
					type: undefined,
				},
			}
		default:
			return state
	}
}

export default uiReducer
