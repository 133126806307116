import React from 'react'
import { useSelector } from 'react-redux'
import {
	actions as bonusAction,
	selectors as bonusSelectors,
} from '../../../redux/components/bonus/'

import Newsletter from '../../newsletter/Newsletter'

import Typography from '@mui/material/Typography'

import { StyledBox } from '../style'

const NewsletterBonusComponent: React.FC = () => {
	const step = 'email'
	const nextStep = 'review'
	const currentStep = useSelector(bonusSelectors.getStepper)
	return (
		<StyledBox className={step === currentStep ? 'active' : 'disabled'}>
			<StyledBox
				className={`bonusCircle  ${step === currentStep ? 'fired' : 'offline'}`}
			>
				<Typography variant="h2">1</Typography>
			</StyledBox>
			<Newsletter
				inputSize="small"
				flexDirection="column"
				stepperAction={() => bonusAction.setStepper(nextStep)}
			/>
		</StyledBox>
	)
}

export default NewsletterBonusComponent
