import React from 'react'

import Newsletter from '../newsletter/Newsletter'
import FooterLogo from './components/FooterLogo'
import FooterNavigation from './components/FooterNavigation'
import FooterCopy from './components/FooterCopy'
import FooterSocialMediaNavBar from './components/FooterSocialMediaNavBar'
import IubendaLinks from '../polices/IubendaLinks'

import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import {
	FooterWrapper,
	FooterContainer,
	FooterLogoAndNewsLetterWrapper,
	FooterLogoAndNewsLetterContainer,
	FooterBottomContainer,
	FooterCopyContainer,
} from './style'

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
	return (
		<>
			<FooterWrapper>
				<CssBaseline />
				<Container>
					<FooterContainer>
						<Box>
							<FooterLogoAndNewsLetterWrapper>
								<Box>
									<FooterLogoAndNewsLetterContainer>
										<Box>
											<FooterLogo />
											<Newsletter inputSize="small" flexDirection="row" />
										</Box>
									</FooterLogoAndNewsLetterContainer>
								</Box>
							</FooterLogoAndNewsLetterWrapper>
							<FooterNavigation />
						</Box>
					</FooterContainer>
					<FooterBottomContainer>
						<Box>
							<IubendaLinks />
							<FooterSocialMediaNavBar />
						</Box>
					</FooterBottomContainer>
				</Container>
				<FooterCopyContainer>
                    <FooterCopy />
                </FooterCopyContainer>
			</FooterWrapper>
		</>
	)
}

export default Footer
