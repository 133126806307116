import React, { useEffect } from 'react'

import { AppDispatch } from './redux/configureStore'
import { useDispatch } from 'react-redux'
import { actions as navigationActions } from './redux/components/navigation'
import { actions as faqsActions } from './redux/components/faqs'

import { ThemeProvider } from '@mui/material/styles'
import { UserProvider, useUser } from './context/UserContext'

import useWindowWidth from './hooks/useWindowWidth'

import { getTokenFromCookies } from './utils/getTokenFromCookies'

import GoogleAnalytics from './components/polices/GoogleAnalytics'
import IubendaScripts from './components/polices/IubendaScripts'
import Header from './components/header/Header'
import Ui from './components/ui/Ui'
import DPRoutes from './DPRoutes'
import Footer from './components/footer/Footer'
import Messages from './components/messages/Messages'

import theme from './styleguide/theme'

import './App.css'

const AppContent: React.FC = () => {
	const { login } = useUser()
	const dispatch: AppDispatch = useDispatch()

	useEffect(() => {
		const token = getTokenFromCookies()
		if (token) {
			login({ username: 'guest' }, token)
		}
	}, [login])

	useEffect(() => {
		dispatch(navigationActions.getNavbar())
		dispatch(faqsActions.getFaqs())
	}, [dispatch])
	return <></>
}

const App: React.FC = () => {
	useWindowWidth()

	return (
		<UserProvider>
			<ThemeProvider theme={theme}>
				<AppContent />
				<GoogleAnalytics trackingId="G-73XE14WFNJ" />
				<IubendaScripts />
				<div className="App">
					<Header />
					<Ui />
					<Messages />
					<DPRoutes />

					<Footer />
					
				</div>
			</ThemeProvider>
		</UserProvider>
	)
}

export default App
