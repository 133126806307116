import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './reducers'
import rootSaga from './sagas'
import { languageMiddleware } from './middleware/middleware'

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(sagaMiddleware).concat(languageMiddleware),
	devTools: process.env.NODE_ENV !== 'production',
})

// then run the saga
sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch

export default store
