import { all } from 'redux-saga/effects'
// Import your sagas here
// import { watchSomeAction } from './someSaga';
import watchUserAuthentication from '../components/auth/saga'
import watchNavigation from '../components/navigation/saga'
import watchFaqs from '../components/faqs/saga'


export default function* rootSaga() {
	yield all([
		// watchSomeAction(),
		watchUserAuthentication(),
		watchNavigation(),
		watchFaqs()
	])
}
