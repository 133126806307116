import styled from 'styled-components'
import { Checkbox as MCheckbox, CheckboxProps } from '@mui/material'

export const StyledCheckbox = styled(MCheckbox)<CheckboxProps>`
	&.MuiButtonBase-root {
		&.MuiCheckbox-root {
			&.Mui-checked {
				color: #ff0000;
			}
		}
	}
`
