// src/i18n/i18n.ts
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpBackend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import store from '../redux/configureStore'
import { actions as uiActions } from '../redux/components/ui'

i18n
	.use(HttpBackend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init(
		{
			supportedLngs: ['en', 'it', 'es'],
			fallbackLng: 'en-US',
			detection: {
				order: [
					'queryString',
					'cookie',
					'localStorage',
					'navigator',
					'htmlTag',
					'path',
					'subdomain',
				],
				caches: ['cookie'],
			},
			debug: false,
			interpolation: {
				escapeValue: false,
			},
			backend: {
				loadPath: '/locales/{{lng}}/{{ns}}.json',
			},
		},
		(err, t) => {
			if (!err) {
				const detectedOrFallbackLanguage = i18n.language
				console.log('Detected language: ', detectedOrFallbackLanguage)
				store.dispatch(uiActions.setLanguage(detectedOrFallbackLanguage))
			}
		},
	)

export default i18n
