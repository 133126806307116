import TYPES from './types'
import {
	TCheckAuth,
	TSetToken,
	TDeleteToken,
	TLoginRequest,
	TSigninRequest,
	TAuthSuccess,
	TAuthFailure,
} from '../../../schemas/auth_d'
import { TLoggedUser, TSignedUser, TStoredUser } from '../../../types/user'

const checkAuthToken = (): TCheckAuth => ({
	type: TYPES.CHECK_AUTH,
})

const setToken = (token: string): TSetToken => ({
	type: TYPES.SET_TOKEN,
	payload: token,
})

const deleteToken = (): TDeleteToken => ({
	type: TYPES.DELETE_TOKEN,
})

const loginUser = (userLogged: TLoggedUser): TLoginRequest => ({
	type: TYPES.LOGIN_REQUEST,
	payload: userLogged,
})

const loginUserSuccess = (userLogged: TStoredUser): TAuthSuccess => ({
	type: TYPES.LOGIN_SUCCESS,
	payload: userLogged,
})

const loginUserFailure = (errorMessage: string): TAuthFailure => ({
	type: TYPES.LOGIN_FAILURE,
	payload: errorMessage,
})

const signinUser = (userSigned: TSignedUser): TSigninRequest => ({
	type: TYPES.SIGNIN_REQUEST,
	payload: userSigned,
})

const siginUserSuccess = (userLogged: TStoredUser): TAuthSuccess => ({
	type: TYPES.SIGNIN_SUCCESS,
	payload: userLogged,
})

const siginUserFailure = (errorMessage: string): TAuthFailure => ({
	type: TYPES.SIGNIN_FAILURE,
	payload: errorMessage,
})

const actions = {
	checkAuthToken,
	setToken,
	deleteToken,
	loginUser,
	loginUserSuccess,
	loginUserFailure,
	signinUser,
	siginUserSuccess,
	siginUserFailure,
}

export default actions
