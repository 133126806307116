import React from 'react'

import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded'
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded'
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded'

interface NavbarIconProps {
	icon: string
}

const NavbarIcons: React.FC<NavbarIconProps> = ({ icon }) => {
	const renderIcon = (iconString: string) => {
		switch (iconString) {
			case 'home':
				return <HomeRoundedIcon />
			case 'bio':
				return <Groups2RoundedIcon />
			case 'books':
				return <MenuBookRoundedIcon />
			case 'contact':
				return <AlternateEmailRoundedIcon />
			default:
				return <></>
		}
	}
	return <>{renderIcon(icon)}</>
}

export default NavbarIcons
