import { TStateFaqs, TFaqsActionTypes, TSetFaqs } from '../../../schemas/faqs_d'
import TYPES from './types'

const initialState: TStateFaqs = {
	faqs: [],
}

const faqReducer = (
	state: TStateFaqs = initialState,
	action: TFaqsActionTypes,
): TStateFaqs => {
	switch (action.type) {
		case TYPES.SET_FAQS:
			return {
				...state,
				faqs: (action as TSetFaqs).payload,
			}
		default:
			return state
	}
}

export default faqReducer
