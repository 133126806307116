import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectors as navigationSelectors } from '../../redux/components/navigation'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { NavItem } from './style'

const Navigation: React.FC = () => {
	const { t } = useTranslation()
	const pages = useSelector(navigationSelectors.getNavigationItems)

	const handleCloseNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		console.log(event.target)
	}

	return (
		<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
			{pages
				.filter(page => page.displayInNavbar)
				.map(page => (
					<Button
						key={page.navigationItemId}
						onClick={handleCloseNavMenu}
						sx={{ my: 2, color: 'black', display: 'block' }}
					>
						<NavItem>
							{
								<NavLink to={page.link}>
									<p>{t(`pages.${page.name}.title`)}</p>
								</NavLink>
							}
						</NavItem>
					</Button>
				))}
		</Box>
	)
}

export default Navigation
