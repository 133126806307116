import { RootState } from '../../reducers'
import { TStateUi } from '../../../schemas/ui_d'
import TYPES from './types'

const getState = (state: RootState): TStateUi =>
	state[TYPES.PREFIX as keyof RootState] as unknown as TStateUi

const getWindowWidth = (state: RootState): number => getState(state).windowWidth
const getLanguage = (state: RootState): string => getState(state).language
const getDeviceType = (state: RootState) => getState(state).deviceType
const getToggleView = (state: RootState) => getState(state).toggleView
const getLoading = (state: RootState) => getState(state).isLoading
const getMessage = (state: RootState) => getState(state).message

const selectors = {
	getWindowWidth,
	getLanguage,
	getDeviceType,
	getToggleView,
	getLoading,
	getMessage,
}

export default selectors
