import React from 'react'
import useCustomLocation from '../hooks/useCustomLocation'
import { useTranslation } from 'react-i18next'

import Container from '@mui/material/Container'

const Bio: React.FC = () => {
	const { t } = useTranslation()
	const path = useCustomLocation()

	return (
		<>
			<Container>
				<h1 className="pageTitle">{t(`pages.${path}.title`)}</h1>
				<section>
					<h1>About Us</h1>
					<p>Discover the Joy of Creation with Draw & Play!</p>
					<p>
						At Draw & Play, we delve into the world of imagination, offering
						diverse realms of activities for every age. Our emblem, the pencil
						entwined with the ampersand, is not just our logo—it's the very
						spirit of our brand. It signifies the beginning of an adventure
						where drawing meets playing, and ideas come alive.
					</p>

					<h2>Our Story</h2>
					<p>
						Draw & Play was born from a simple yet profound idea: that everyone,
						from the curious child to the wise adult, harbors a creative spirit.
						We set out to craft tools that channel this creativity into
						something tangible, something delightful, something educational.
					</p>

					<h2>Our Collections</h2>
					<h3>For the Adults:</h3>
					<p>
						Unwind, challenge yourself, and sharpen your mind with our
						meticulously designed collection. Each book is an invitation to get
						lost in the intricate dance of intellect and relaxation.
					</p>
					<ul>
						<li>
							<strong>Word Search:</strong> Exercise your brain with a maze of
							letters and the thrill of the hunt.
						</li>
						<li>
							<strong>Crossword:</strong> Test your knowledge and wit against
							the classic grids that have captivated for generations.
						</li>
						<li>
							<strong>Sudoku & Double Puzzle:</strong> Dive into numerical
							ballets and double the puzzle fun.
						</li>
						<li>
							<strong>Mazes:</strong> Find your way through complex labyrinths
							that twist and turn with every page.
						</li>
						<li>
							<strong>Cryptograms & Word Match:</strong> Decipher codes and pair
							words in a mental match that's both challenging and rewarding.
						</li>
						<li>
							<strong>Missing Letters:</strong> Refine your attention to detail
							by filling in the gaps, one letter at a time.
						</li>
					</ul>

					<h3>For the Kids:</h3>
					<p>
						Spark a lifetime of learning with books that color the foundations
						of education with fun.
					</p>
					<ul>
						<li>
							<strong>Coloring Books:</strong> Blend playtime with creativity,
							filling the world with their own hues.
						</li>
						<li>
							<strong>Activity Books & Workbooks:</strong> Transform every
							lesson into a playful quest, whether it's mastering ABCs, 123s, or
							scissor skills.
						</li>
						<li>
							<strong>Preschool Books:</strong> Set the stage for success with
							activities designed for the youngest learners, laying down the
							joyous blocks of knowledge.
						</li>
					</ul>

					<h2>Our Commitment</h2>
					<p>
						Draw & Play champions eco-friendly practices, educational
						innovation, and the sheer joy of making every moment an opportunity
						to learn and grow. We strive to be a trusted partner in the creative
						journey of our readers, fostering a community where every mind can
						thrive.
					</p>

					<h2>Join the Draw & Play Family</h2>
					<p>
						We invite you to step into our world—a place where every word puzzle
						and every colorful page is a step toward creative discovery. Stay
						connected with us on [social media] and join our newsletter for
						updates on our latest offerings, imaginative tips, and exclusive
						deals. Together, let's turn every day into an opportunity for
						learning and play!
					</p>
				</section>
			</Container>
		</>
	)
}

export default Bio
