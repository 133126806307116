import styled from 'styled-components'

export const AuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 600px) {
    height: 600px;
  }

  & .MuiGrid-root {
    flex-wrap: nowrap;
    height: 100%;

    &.MuiGrid-container {
        @media (min-width: 600px) {
          padding: 0;
          margin-top: auto;
          margin-bottom: auto;
          height: 60%;
        }
      }

      & .MuiGrid-item {
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (min-width: 600px) {
          min-width: 0%;
        }

        & form {
          padding: 0px 30px;

          @media (min-width: 600px) {
            min-height: 360px;
            padding: 0 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-self: center;
          }
        }
      }
    }
  }
`

export const SectionTitle = styled.img`
	height: 50px;
	width: auto;
`
