const PREFIX = 'AUTH'
const CHECK_AUTH = `${PREFIX}.CHECK_AUTH`
const SET_TOKEN = `${PREFIX}.SET_TOKEN`
const DELETE_TOKEN = `${PREFIX}.DELETE_TOKEN`

const LOGIN_REQUEST = `${PREFIX}.LOGIN_REQUEST`
const LOGIN_SUCCESS = `${PREFIX}.LOGIN_SUCCESS`
const LOGIN_FAILURE = `${PREFIX}.LOGIN_FAILURE`

const SIGNIN_REQUEST = `${PREFIX}.SIGNIN_REQUEST`
const SIGNIN_SUCCESS = `${PREFIX}.SIGNIN_SUCCESS`
const SIGNIN_FAILURE = `${PREFIX}.SIGNIN_FAILURE`

const TYPES = {
	PREFIX,
	CHECK_AUTH,
	SET_TOKEN,
	DELETE_TOKEN,
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGIN_FAILURE,
	SIGNIN_REQUEST,
	SIGNIN_SUCCESS,
	SIGNIN_FAILURE,
}

export default TYPES
