import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectors as uiSelectors } from '../../redux/components/ui'

import NewsletterBonusComponent from './components/NewsletterBonusComponent'
import BookCoverBonusComponent from './components/BookCoverBonusComponet'
import ReviewBonusComponent from './components/ReviewBonusComponent'
import FreeGiftBonusComponent from './components/FreeGiftBonusComponent'

import {
	StyledBonusGridContainer,
	StyledBonusGridItem,
} from '../../styleguide/Grid'

const BonusComponent: React.FC = () => {
	const deviceType = useSelector(uiSelectors.getDeviceType)
	const [isMobile, setIsMobile] = useState<boolean>(false)

	useEffect(() => {
		deviceType === 'mobile' ? setIsMobile(true) : setIsMobile(false)
	}, [deviceType])

	console.log(isMobile, deviceType)

	return (
		<>
			<StyledBonusGridContainer
				container
				sx={{ height: isMobile ? '1500px' : '700px' }}
			>
				{!isMobile && (
					<StyledBonusGridItem
						item
						xs={12}
						md={4}
						sx={{
							height: '100%',
							padding: '20px',
							boxSizing: 'border-box',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<NewsletterBonusComponent />
					</StyledBonusGridItem>
				)}
				<StyledBonusGridItem
					item
					xs={12}
					md={4}
					sx={{ display: 'flex', flexDirection: 'column' }}
				>
					<StyledBonusGridItem
						item
						sx={{
							width: '100%',
							height: isMobile ? '25%' : '70%',
							padding: '20px',
							boxSizing: 'border-box',
						}}
					>
						<BookCoverBonusComponent />
					</StyledBonusGridItem>

					{isMobile && (
						<StyledBonusGridItem
							item
							sx={{
								height: '25%',
								padding: '20px',
								boxSizing: 'border-box',
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<NewsletterBonusComponent />
						</StyledBonusGridItem>
					)}
					<StyledBonusGridItem
						item
						sx={{
							width: '100%',
							height: isMobile ? '25%' : '30%',
							padding: '20px',
							boxSizing: 'border-box',
						}}
					>
						<ReviewBonusComponent />
					</StyledBonusGridItem>
					{isMobile && (
						<StyledBonusGridItem
							item
							sx={{
								width: '100%',
								height: '25%',
								padding: '20px',
								boxSizing: 'border-box',
							}}
						>
							<FreeGiftBonusComponent />
						</StyledBonusGridItem>
					)}
				</StyledBonusGridItem>
				{!isMobile && (
					<StyledBonusGridItem
						item
						xs={12}
						sm={4}
						sx={{ height: '100%', padding: '20px', boxSizing: 'border-box' }}
					>
						<FreeGiftBonusComponent />
					</StyledBonusGridItem>
				)}
			</StyledBonusGridContainer>
		</>
	)
}

export default BonusComponent
