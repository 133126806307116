import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Chip from '@mui/material/Chip'

import {
	FooterNavigationContainer,
	FooterNavigationContainerMobile,
} from '../style'

const FooterNavigation: React.FC = () => {
	return (
		<>
			<FooterNavigationContainer>
				<Box>
					<Typography variant="body2" fontWeight={600}>
						Product
					</Typography>
					<Link color="text.secondary" href="#">
						Features
					</Link>
					<Link color="text.secondary" href="#">
						Testimonials
					</Link>
					<Link color="text.secondary" href="#">
						Highlights
					</Link>
					<Link color="text.secondary" href="#">
						Pricing
					</Link>
					<Link color="text.secondary" href="#">
						FAQs
					</Link>
				</Box>
				<Box>
					<Typography variant="body2" fontWeight={600}>
						Company
					</Typography>
					<Link color="text.secondary" href="#">
						About us
					</Link>
					<Link color="text.secondary" href="#">
						Careers
					</Link>
					<Link color="text.secondary" href="#">
						Press
					</Link>
				</Box>
				<Box>
					<Typography variant="body2" fontWeight={600}>
						Legal
					</Typography>
					<Link color="text.secondary" href="#">
						Terms
					</Link>
					<Link color="text.secondary" href="#">
						Privacy
					</Link>
					<Link color="text.secondary" href="#">
						Contact
					</Link>
				</Box>
			</FooterNavigationContainer>
			<FooterNavigationContainerMobile>
				<Box>
					<Typography variant="body2" fontWeight={600}>
						Product
					</Typography>
					<Box>
						<Chip
							label="Features"
							component="a"
							href="#basic-chip"
							color="primary"
							clickable
						/>
						<Chip
							label="Testimonials"
							component="a"
							href="#basic-chip"
							color="primary"
							clickable
						/>
						<Chip
							label="Highlights"
							component="a"
							href="#basic-chip"
							color="primary"
							clickable
						/>
						<Chip
							label="Pricing"
							component="a"
							href="#basic-chip"
							color="primary"
							clickable
						/>
						<Chip
							label="FAQs"
							component="a"
							href="#basic-chip"
							color="primary"
							clickable
						/>
					</Box>
				</Box>
				<Box>
					<Typography variant="body2" fontWeight={600}>
						Company
					</Typography>
					<Box>
						<Chip
							label="About us"
							component="a"
							href="#basic-chip"
							color="secondary"
							clickable
						/>
						<Chip
							label="Careers"
							component="a"
							href="#basic-chip"
							color="secondary"
							clickable
						/>
						<Chip
							label="Press"
							component="a"
							href="#basic-chip"
							color="secondary"
							clickable
						/>
					</Box>
				</Box>
				<Box>
					<Typography variant="body2" fontWeight={600}>
						Legal
					</Typography>
					<Box>
						<Chip label="Terms" component="a" href="#basic-chip" clickable />
						<Chip label="Privacy" component="a" href="#basic-chip" clickable />
						<Chip label="Contact" component="a" href="#basic-chip" clickable />
					</Box>
				</Box>
			</FooterNavigationContainerMobile>
		</>
	)
}

export default FooterNavigation
