import styled from 'styled-components'

export const NavItem = styled.div`
	font-family: 'handwrite';

	& a {
		text-decoration: none;
		color: #ccc;
		font-size: 15px;
		font-weight: 600;
		text-transform: capitalize;

		&:hover {
			color: #f00;
		}

		& .MuiListItemIcon-root {
			color: #ccc;

			& svg {
				fill: #ccc;
			}
		}

		&.active {
			& p {
				color: #000;
				&::first-letter {
					color: #f00 !important;
				}
			}

			& .MuiListItemText-root {
				& p.MuiTypography-root.MuiTypography-body1 {
					font-family: handwrite;
					font-weight: 600;
					font-size: 1.2rem;
					color: #000;
					&::first-letter {
						color: #f00 !important;
					}
				}
			}

			& .MuiListItemIcon-root {
				color: #f00;

				& svg {
					fill: #f00;
				}
			}
		}
	}
`

export const MobileNavContainer = styled.header`
	& .MuiAppBar-root {
		background: #f00;
	}
`

export const NavItemMobile = styled.div`
	& a {
		& svg {
			fill: #000;
		}

		&.active {
			& svg {
				fill: #fff;
			}
		}
	}
`
