import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import DynamicForm from '../../dynamic-form/DynamicForm';
import { getLoginConfig } from './authConfig/loginFormConfig';
import { AppDispatch } from '../../../redux/configureStore';

const Login: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const loginConfig = getLoginConfig(t, dispatch);

  return <DynamicForm config={loginConfig} />;
};

export default Login;
