import styled from 'styled-components'
import { CircularProgress, CircularProgressProps } from '@mui/material'

export const StyledCircularProgress = styled(
	CircularProgress,
)<CircularProgressProps>`
	&.MuiCircularProgress-root {
		&.MuiCircularProgress-colorPrimary {
			color: rgba(255, 0, 0, 1);
		}
	}
`
