import React from 'react'
import { MenuItem, FormHelperText } from '@mui/material'

import { StyledFormControl } from '../../styleguide/forms/FormControl'
import { StyledSelect } from '../../styleguide/forms/Select'
import { StyledInputLabel } from '../../styleguide/forms/InputLabel'

type TOption = {
	label: string
	value: string
}

interface CustomSelectProps {
	field: any // Fornito da Formik quando usato con <Field component={...}/>
	form: any // Fornito da Formik quando usato con <Field component={...}/>
	label: string
	type: 'select'
	options: TOption[]
	onSelect?: (value: string) => void // Funzione per passare il valore selezionato al genitore
}

const CustomSelect: React.FC<CustomSelectProps> = ({
	field,
	form,
	label,
	type,
	options,
	onSelect,
	...props
}) => {
	return (
		<>
			<StyledFormControl>
				<StyledInputLabel id={`${type}-label`}>{label}</StyledInputLabel>
				<StyledSelect
					{...props}
					{...field}
					labelId={`${type}-label`}
					id={type}
					type={type}
					label={label}
					fullWidth
					error={form.touched[field.name] && !!form.errors[field.name]}
					onChange={e => {
						form.setFieldValue(field.name, e.target.value)
					}}
				>
					{options?.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</StyledSelect>
				{form.touched[field.name] && form.errors[field.name] && (
					<FormHelperText>Help</FormHelperText>
				)}
			</StyledFormControl>
		</>
	)
}

export default CustomSelect
