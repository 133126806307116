// sagas.js
import { call, put, takeLatest } from 'redux-saga/effects'
import { TLoginRequest, TSigninRequest } from '../../../schemas/auth_d'
import {
	TLoggedUser,
	TSignedUser,
	TResponseStoredUser,
} from '../../../types/user'
import { logUser, signUser } from '../../../api/authApi'
import authActions from './actions'
import { actions as uiActions } from '../ui'
import TYPES from './types'

function* handleLogin(action: TLoginRequest) {
	try {
		const data: TLoggedUser = action.payload
		const result: TResponseStoredUser = yield call(logUser, data)
		if (result?.data) {
			yield put(authActions.loginUserSuccess(result.data))
		}
		yield put(uiActions.setMessage(result.message))
	} catch (error: any) {
		yield put(uiActions.setMessage(error.message))
		console.log(error)
	}
}

function* handleSignin(action: TSigninRequest) {
	try {
		const data: TSignedUser = action.payload
		const result: TResponseStoredUser = yield call(signUser, data)
		yield put(authActions.siginUserSuccess(result.data))
		yield put(uiActions.setMessage(result.message))
	} catch (error: any) {
		yield put(uiActions.setMessage(error.message))
	}
}

// Watchers
export default function* watchUserAuthentication() {
	yield takeLatest(TYPES.LOGIN_REQUEST, handleLogin)
	yield takeLatest(TYPES.SIGNIN_REQUEST, handleSignin)
}
