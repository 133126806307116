import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'

import { StyledCPContainer, StyledCPCircle } from './style'

interface CircularProgressProps {
	degree: number
	color: string
	title: string
}

const CircularProgress: React.FC<CircularProgressProps> = ({
	degree,
	color,
	title,
}) => {
	const [currentDegree, setCurrentDegree] = useState(0)

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentDegree(oldDegree => {
				if (oldDegree < degree) {
					return oldDegree + 1
				}
				clearInterval(interval)
				return oldDegree
			})
		}, 50)

		return () => clearInterval(interval)
	}, [degree])

	return (
		<StyledCPContainer>
			<StyledCPCircle
				style={{
					background: `conic-gradient(${color} ${currentDegree}%, #222 0%)`,
				}}
			>
				<Typography
					className="number"
					variant="h2"
					component="h2"
					sx={{ color: color }}
				>
					{currentDegree}
					<Typography variant="h5" component="span" sx={{ color: color }}>
						%
					</Typography>
				</Typography>

				<Typography variant="h4">{title}</Typography>
			</StyledCPCircle>
		</StyledCPContainer>
	)
}

export default CircularProgress
