import React from 'react'
import { useField } from 'formik'
import { CheckboxProps } from '@mui/material'

import { StyledCheckbox } from './style'

// In Checkbox.tsx o dove hai definito il tuo componente Checkbox
interface ICheckboxProps extends CheckboxProps {
	name: string
}

const Checkbox: React.FC<ICheckboxProps> = ({ name, ...props }) => {
	// useField() restituisce [formik.getFieldProps(), formik.getFieldMeta()]
	const [field] = useField({ name, type: 'checkbox' })

	return <StyledCheckbox {...field} {...props} />
}

export default Checkbox
