import React from 'react'
import useCustomLocation from '../hooks/useCustomLocation'
import { useTranslation } from 'react-i18next'

import Container from '@mui/material/Container'

const DownloadBonus: React.FC = () => {
	const { t } = useTranslation()
	const path = useCustomLocation()

	return (
		<Container>
			<h1 className="pageTitle">{t(`pages.${path}.title`)}</h1>
		</Container>
	)
}

export default DownloadBonus
