// sagas.js
import { call, put, takeLatest } from 'redux-saga/effects'
import {
	TNavbarItem,
	TNavigationResponse
} from '../../../types/navigation'
import { getNavigation } from '../../../api/navigationApi'
import navbarActions from './actions'
import { actions as uiActions } from '../ui'
import TYPES from './types'

function* handleNavigation() {
	try {
		
		const result: TNavigationResponse = yield call(getNavigation)
		if (result?.data) {
			const navigationItems: TNavbarItem[] = Object.values(result.data)
			yield put(navbarActions.setNavbar(navigationItems))
		}
		yield put(uiActions.setMessage(result.message))
	} catch (error: any) {
		yield put(uiActions.setMessage(error.message))
		console.log(error)
	}
}

// Watchers
export default function* watchNavigation() {
	yield takeLatest(TYPES.GET_NAVBAR, handleNavigation)

}
