import TYPES from './types'
import { SimpleAction } from '../../../schemas/action_d'

const incrementCounter = (): SimpleAction => ({
	type: TYPES.INCREMENT,
})

const decrementCounter = (): SimpleAction => ({
	type: TYPES.DECREMENT,
})

const resetCounter = (): SimpleAction => ({
	type: TYPES.RESET,
})

const actions = {
	incrementCounter,
	decrementCounter,
	resetCounter,
}

export default actions
