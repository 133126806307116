import React from 'react';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import {
  FooterLegalContainer,
  FooterBottomLink,
  FooterBottomDot,
} from '../footer/style';

const IubendaLinks: React.FC = () => (
  <div>
    <FooterLegalContainer>
      <FooterBottomLink>
        <Typography variant="inherit">
          <Link
            href="https://www.iubenda.com/privacy-policy/57771424"
            underline="none"
            className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
            title="Privacy Policy"
          >
            Privacy Policy
          </Link>
        </Typography>
      </FooterBottomLink>
      <FooterBottomDot>
        <Typography variant="body2">•</Typography>
      </FooterBottomDot>
      <FooterBottomLink>
        <Typography variant="inherit">
          <Link
            href="https://www.iubenda.com/privacy-policy/57771424/cookie-policy"
            underline="none"
            className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
            title="Cookie Policy"
          >
            Cookie Policy
          </Link>
        </Typography>
      </FooterBottomLink>
    </FooterLegalContainer>
  </div>
);

export default IubendaLinks;
