import { TStepperActionTypes, TStateBonus } from '../../../schemas/bonus_d'
import TYPES from './types'

const initialState: TStateBonus = {
	stepper: 'email',
}

const bonusReducer = (
	state: TStateBonus = initialState,
	action: TStepperActionTypes,
): TStateBonus => {
	switch (action.type) {
		case TYPES.SET_STEPPER:
			return {
				...state,
				stepper: action.payload,
			}
		default:
			return state
	}
}

export default bonusReducer
