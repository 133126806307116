import { RootState } from '../../reducers'
import { TStateNavigation } from '../../../schemas/navigation_d'
import { TNavbarItem } from '../../../types/navigation'
import TYPES from './types'

const getState = (state: RootState): TStateNavigation =>
	state[TYPES.PREFIX as keyof RootState] as unknown as TStateNavigation

const getNavigationItems = (state: RootState): TNavbarItem[] => getState(state).navigation

const selectors = {
	getNavigationItems,
}

export default selectors
