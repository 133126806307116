import styled from 'styled-components'
import { Grid, GridProps } from '@mui/material'

export const StyledBonusGridContainer = styled(Grid)<GridProps>`
	&.MuiGrid-root {
		&.MuiGrid-container {
		}
	}
`

export const StyledBonusGridItem = styled(Grid)<GridProps>`
	&.MuiGrid-root {
		&.MuiGrid-item {
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid #ddd;
		}
	}
`
