import styled from 'styled-components'
import { InputLabel, InputLabelProps } from '@mui/material'

export const StyledInputLabel = styled(InputLabel)<InputLabelProps>`
	&.MuiFormLabel-root {
		&.MuiInputLabel-root {
			display: flex;
			justify-content: 'start';
		}
	}
`
