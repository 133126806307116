import { TStateNavigation, TNavigationActionTypes, TSetNavigation } from '../../../schemas/navigation_d'
import TYPES from './types'

const initialState: TStateNavigation = {
	navigation: [],
}

const navigationReducer = (
	state: TStateNavigation = initialState,
	action: TNavigationActionTypes,
): TStateNavigation => {
	switch (action.type) {
		case TYPES.SET_NAVBAR:
			return {
				...state,
				navigation: (action as TSetNavigation).payload,
			}
		default:
			return state
	}
}

export default navigationReducer
