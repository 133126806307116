import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Importazione diretta dei componenti
import Home from './views/Home';
import Bio from './views/Bio';
import Contact from './views/Contact';
import Books from './views/Books';
import Book from './views/Book';
import DownloadBonus from './views/DownloadBonus';
import Auth from './views/Auth';
import Bonus from './views/Bonus';
import NotFound from './views/NotFound';

const DPRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/auth" element={<Auth />} />
      <Route path="/bio" element={<Bio />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/books" element={<Books />} />
      <Route path="/book/:id" element={<Book />} />
      <Route path="/download-bonus" element={<DownloadBonus />} />
      <Route path="/bonus" element={<Bonus />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default DPRoutes;
