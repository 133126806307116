import { Page } from '../types/navigation'

export const pages: Page[] = [
	{
		id: 1,
		name: 'home',
		link: '/',
		navbar: true,
	},
	{
		id: 2,
		name: 'bio',
		link: '/bio',
		navbar: true,
	},
	{
		id: 3,
		name: 'books',
		link: '/books',
		navbar: true,
	},
	{
		id: 4,
		name: 'book',
		link: '/book',
		navbar: false,
	},
	{
		id: 5,
		name: 'contact',
		link: '/contact',
		navbar: true,
	},
	{
		id: 6,
		name: 'dowloadbonus',
		link: '/dowloadbonus',
		navbar: false,
	},
	{
		id: 7,
		name: 'notfound',
		link: '/',
		navbar: false,
	},
]

export const MOBILE_BREAKPOINT = 600
export const DESKTOP_BREAKPOINT = 1200
