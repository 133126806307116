import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectors as bonusSelectors } from '../../../redux/components/bonus/'

import { StyledButton } from '../../../styleguide/Button'

import Typography from '@mui/material/Typography'

import { StyledBox } from '../style'

const FreeGiftBonusComponent: React.FC = () => {
	const step = 'bonus'
	const currentStep = useSelector(bonusSelectors.getStepper)
	const { t } = useTranslation()

	return (
		<StyledBox className={step === currentStep ? 'active' : 'disabled'}>
			<StyledBox
				className={`bonusCircle  ${step === currentStep ? 'fired' : 'offline'}`}
			>
				<Typography variant="h2">3</Typography>
			</StyledBox>
			<StyledButton variant="contained" disabled={step !== currentStep}>
				{t(`Get Your Bonus`)}
			</StyledButton>
		</StyledBox>
	)
}

export default FreeGiftBonusComponent
