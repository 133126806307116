import { Select, SelectProps } from '@mui/material';
import styled from '@mui/material/styles/styled';

interface StyledSelectProps extends SelectProps {
  dynamicbordercolor: string;
  dynamicbordercolorhover?: string;
  dynamicbordercolorfocused?: string;
}

export const StyledSelect = styled(Select)<StyledSelectProps>`
  &.MuiInputBase-root {
    text-align: left;
    font-size: inherit;
    &.MuiOutlinedInput-root {
      width: 100%;

      & fieldset.MuiOutlinedInput-notchedOutline {
        border-color: ${({ dynamicbordercolor }) => dynamicbordercolor};
      }

      &:hover fieldset {
        border-color: ${({ dynamicbordercolorhover}) => dynamicbordercolorhover ? dynamicbordercolorhover : '#000'};
      }

      &.Mui-focused fieldset {
        border-color: ${({ dynamicbordercolorfocused}) => dynamicbordercolorfocused ? dynamicbordercolorfocused : '#f00'};
      }
    }
  }
`;
