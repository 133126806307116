export const tutorialSteps = [
    {
      label: 'San Francisco – Oakland Bay Bridge, United States',
      imgPath: './assets/images/hero/01.jpg',
      link: '/san-francisco',
    },
    {
      label: 'Bird',
      imgPath: './assets/images/hero/02.jpg',
      link: '/bird',
    },
    {
      label: 'Bali, Indonesia',
      imgPath: './assets/images/hero/03.jpg',
      link: '/bali',
    },
    {
      label: 'NeONBRAND Digital Marketing, Las Vegas, United States',
      imgPath: './assets/images/hero/04.jpg',
      link: '/las-vegas',
    },
  ];