import React from 'react'
import { useTranslation } from 'react-i18next'
import NewsletterForm from './components/NewsletterForm'

import Typography from '@mui/material/Typography'

import { NewsletterTitle, NewsletterDescription } from './style'

interface INewsletterProps {
	inputSize: 'small' | 'medium'
	flexDirection: 'row' | 'column'
	stepperAction?: () => { type: string; payload: 'email' | 'review' | 'bonus' }
}

const Newsletter: React.FC<INewsletterProps> = ({
	inputSize,
	flexDirection,
	stepperAction,
}) => {
	const { t } = useTranslation()
	return (
		<>
			<NewsletterTitle>
				<Typography variant="body2" gutterBottom={true}>
					{t(`footer.newsletter.title`)}
				</Typography>
			</NewsletterTitle>
			<NewsletterDescription>
				<Typography variant="body2">
					{t(`footer.newsletter.description`)}
				</Typography>
			</NewsletterDescription>
			<NewsletterForm
				inputSize={inputSize}
				direction={flexDirection}
				stepperAction={stepperAction}
			/>
		</>
	)
}

export default Newsletter
