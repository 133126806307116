import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../redux/configureStore'

import * as Yup from 'yup'
import DynamicForm, { FormConfig } from '../../dynamic-form/DynamicForm'

import { capitalize } from '../../../utils/capitalize'

interface INewsletterFormProps {
	inputSize: 'small' | 'medium'
	direction: 'row' | 'column'
	stepperAction?: () => { type: string; payload: 'email' | 'review' | 'bonus' }
}

const NewsletterForm: React.FC<INewsletterFormProps> = ({
	inputSize,
	direction,
	stepperAction,
}) => {
	const { t } = useTranslation()
	const dispatch: AppDispatch = useDispatch()

	// Schema di validazione con Yup
	const NewsletterSchema = Yup.object().shape({
		email: Yup.string()
			.email(capitalize(t(`form.validation.email`)))
			.required(capitalize(t(`form.validation.required`))),
	})

	const newsletterConfig: FormConfig = {
		initialValues: { email: '' },
		validationSchema: NewsletterSchema,
		onSubmit: (values, { setSubmitting, resetForm }) => {
			setSubmitting(false)
			console.log(values)
			if (stepperAction) {
				dispatch(stepperAction())
			}
			resetForm()
		},
		validateOnMount: true,
		fields: [
			{
				name: 'email',
				type: 'email', // Modifica da "text" a "email" per corrispondenza con il tipo
				label: capitalize(t(`form.input.newsletter.placeholder`)),
				outlinedSize: inputSize,
			},
		],
		buttonLabel: capitalize(t(`button.newsletter`)),
		fieldDirection: direction,
	}

	return (
		<>
			<DynamicForm config={newsletterConfig} />
		</>
	)
}

export default NewsletterForm
