import { useEffect } from 'react';

const IubendaScripts: React.FC = () => {
  useEffect(() => {
    const loadScript = (src: string) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
    };

    loadScript('https://cs.iubenda.com/autoblocking/3622206.js');
    loadScript('//cdn.iubenda.com/cs/gpp/stub.js');
    loadScript('//cdn.iubenda.com/cs/iubenda_cs.js');

    const iubendaConfig = document.createElement('script');
    iubendaConfig.type = 'text/javascript';
    iubendaConfig.innerHTML = `
      var _iub = _iub || [];
      _iub.csConfiguration = {
        askConsentAtCookiePolicyUpdate: true,
        enableFadp: true,
        enableLgpd: true,
        enableUspr: true,
        fadpApplies: true,
        floatingPreferencesButtonDisplay: 'bottom-right',
        perPurposeConsent: true,
        preferenceCookie: { expireAfter: 180 },
        siteId: 3622206,
        usprApplies: true,
        whitelabel: false,
        cookiePolicyId: 57771424,
        lang: 'it',
        banner: {
          acceptButtonDisplay: true,
          closeButtonDisplay: false,
          customizeButtonDisplay: true,
          explicitWithdrawal: true,
          listPurposes: true,
          ownerName: 'www.draw-and-play.com',
          position: 'float-top-center',
          rejectButtonDisplay: true,
          showTitle: false,
          showTotalNumberOfProviders: true,
        },
      };
    `;
    document.body.appendChild(iubendaConfig);
  }, []);

  return null;
};

export default IubendaScripts;
