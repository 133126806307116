import styled from 'styled-components'
import { Box } from '@mui/material'

interface StyledBoxProps {
	flexDirection?: string
}

export const StyledBox = styled(Box)<StyledBoxProps>`
	&.MuiBox-root {
		position: relative;

		&.languageWrapper {
			min-widnth: 120px;
			diplay: flex;
			justify-content: flex-end;
			align-item: center;

			@media (min-width: 600px) {
				min-width: 250px;
			}
		}

		&.newsletterFormWrapper {
			margin-left: -10px;
			width: 100% !important;
			display: flex !important;
			flex-direction: colomn !important;

			@media (min-width: 600px) {
				flex-direction: ${props => props.flexDirection} !important;
			}
		}
	}
`
