import styled from 'styled-components'
import { TextField, TextFieldProps } from '@mui/material'

export const StyledTextField = styled(TextField)<TextFieldProps>`
	& .MuiFormLabel-root {
		&.MuiInputLabel-root {
			&.Mui-focused {
				color: #ff0000;
			}
		}
	}

	& .MuiInputBase-root {
		&.MuiOutlinedInput-root {
			width: 100%;

			& fieldset.MuiOutlinedInput-notchedOutline {
				border-color: #94a6b8;
			}

			&:hover fieldset {
				border-color: #000;
			}

			&.Mui-focused fieldset {
				border-color: #ff0000;
			}
		}
	}
`
