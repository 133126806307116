import React from 'react'
import { useTranslation } from 'react-i18next'
// import { useDispatch, useSelector } from 'react-redux'
// import { AppDispatch } from '../../../redux/configureStore'
// import { actions as authActions } from '../../../redux/components/auth'
// import { selectors as uiSelectors } from '../../../redux/components/ui'

import * as Yup from 'yup'

import { FormConfig } from '../../dynamic-form/DynamicForm'
import DynamicForm from '../../dynamic-form/DynamicForm'

import { capitalize } from '../../../utils/capitalize'

const ContactForm: React.FC = () => {
	const { t } = useTranslation()
	// const dispatch: AppDispatch = useDispatch()
	// const lang = useSelector(uiSelectors.getLanguage)

	const ContactSchema = Yup.object().shape({
		name: Yup.string()
			.matches(/^[a-zA-Z0-9\s]+$/, {
				message: capitalize(t(`form.validation.name`)),
				excludeEmptyString: true,
			})
			.required(capitalize(t(`form.validation.required`))),
		email: Yup.string()
			.email(capitalize(t(`form.validation.email`)))
			.required(capitalize(t(`form.validation.required`))),
		messageObject: Yup.string()
			.notOneOf([''], capitalize(t(`form.validation.required`)))
			.required(capitalize(t(`form.validation.required`))),
		message: Yup.string()
			.matches(/^[a-zA-Z0-9\s]+$/, {
				message: capitalize(t(`form.validation.textarea`)),
				excludeEmptyString: true,
			})
			.required(capitalize(t(`form.validation.required`))),
	})

	const contactConfig: FormConfig = {
		initialValues: {
			name: '',
			email: '',
			emailMessageObject: '',
			message: '',
		},
		validationSchema: ContactSchema,
		onSubmit: (values, { setSubmitting, resetForm, validateForm }) => {
			setSubmitting(false)
			// const data = { ...values, preferences: { language: lang } };
			// dispatch(authActions.signinUser(data));
			console.log(values)
			resetForm()
			validateForm()
		},
		validateOnMount: true,
		fields: [
			{
				name: 'name',
				type: 'text',
				label: capitalize(t(`form.input.contacts.name`)),
			},
			{
				name: 'email',
				type: 'email',
				label: capitalize(t(`form.input.contacts.email`)),
			},
			{
				name: 'emailMessageObject',
				label: capitalize(t(`form.input.contacts.emailMessageObject`)),
				type: 'select',
				options: [
					{ label: 'Message Object', value: '' },
					{ label: 'Info', value: 'info' },
					{ label: 'Bonus', value: 'bonus' },
					{ label: 'Discount', value: 'discount' },
					{ label: 'Newsletter', value: 'newsletter' },
					{ label: 'Custom Object...', value: 'custom' },
				],
			},
			{
				name: 'message',
				type: 'textarea',
				label: capitalize(t(`form.input.contacts.textarea`)),
			},
		],
		buttonLabel: capitalize(t(`button.signin`)),
	}

	return <DynamicForm config={contactConfig} />
}

export default ContactForm
