import { RootState } from '../../reducers'
import { TStateBonus, TStepper } from '../../../schemas/bonus_d'
import TYPES from './types'

const getState = (state: RootState): TStateBonus =>
	state[TYPES.PREFIX as keyof RootState] as unknown as TStateBonus

const getStepper = (state: RootState): TStepper => getState(state).stepper

const selectors = {
	getStepper,
}

export default selectors
