import TYPES from './types'
import { TGetFaqs, TSetFaqs } from '../../../schemas/faqs_d'
import {TFaqsSection} from '../../../types/faqs'

const getFaqs = (): TGetFaqs => ({
	type: TYPES.GET_FAQS,
})

const setFaqs = (faqs: TFaqsSection[]): TSetFaqs => ({
	type: TYPES.SET_FAQS,
	payload: faqs
})


const actions = {
	getFaqs,
	setFaqs,
}

export default actions
