// src/components/style.ts

import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

export const Root = styled.div`
  max-width: 100%;
  flex-grow: 1;
  position: relative;
`;

export const ImgContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &:hover button {
    display: block;
  }
`;

export const Img = styled.img`
  height: auto;
  display: block;
  max-width: 100%;
  overflow: hidden;
  width: 100%;
`;

export const NavButton = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  background: none;
  font-size: 5rem;
  display: none;

  @media (min-width: 751px) {
    font-size: 20rem;
  }

  &:hover {
    background: none;
  }
`;

export const PrevButton = styled(NavButton)`
  left: -25px;

  @media (min-width: 751px) {
    left: -100px;
  }
  
`;

export const NextButton = styled(NavButton)`
  right: -25px;

  @media (min-width: 751px) {
    right: -100px;
  }
  
`;

export const LinkText = styled(Link)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-decoration: none;
  font-size: 2rem;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.5rem 1rem;
  border-radius: 5px;
`;
