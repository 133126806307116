import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field, FormikHelpers, FieldProps } from 'formik';
import { ObjectSchema } from 'yup';

import CustomTextField from './CustomTextField';
import CustomSelect from './CustomSelect';

import { StyledBox } from '../../styleguide/Box';
import { StyledButton } from '../../styleguide/Button';
import CheckboxLabel from '../../styleguide/forms/CheckboxLabel/CheckboxLabel';
import Checkbox from '../../styleguide/forms/Checkbox/Checkbox';

import Box from '@mui/material/Box';

import { StyledForm } from './style';

interface IOptions {
  label: string;
  value: string;
}

interface FormField {
  name: string;
  label: string;
  type: 'text' | 'password' | 'email' | 'textarea' | 'checkbox' | 'radio' | 'select';
  options?: IOptions[]; // Usato solo per i campi radio
  validatePassword?: boolean;
  outlinedSize?: 'small' | 'medium';
}

export interface FormConfig {
  initialValues: Record<string, any>;
  validationSchema: ObjectSchema<any>;
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void;
  validateOnMount?: boolean;
  fields: FormField[];
  buttonLabel: string;
  fieldDirection?: 'row' | 'column';
}

interface DynamicFormProps {
  config: FormConfig;
}

const DynamicForm: React.FC<DynamicFormProps> = ({ config }) => {
  const { t } = useTranslation();

  const {
    initialValues,
    validationSchema,
    onSubmit,
    validateOnMount = true,
    fields,
    buttonLabel,
    fieldDirection,
  } = config;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        onSubmit(values, actions);
      }}
      validateOnMount={validateOnMount}
    >
      {({ isValid, isSubmitting, resetForm, validateForm }) => (
        <StyledForm>
          <StyledBox
            className="newsletterFormWrapper"
            flexDirection={fieldDirection ? fieldDirection : 'column'}
          >
            <Box
              sx={{
                '& > :not(style)': { m: 1 },
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {fields.map(customField => {
                switch (customField.type) {
                  case 'text':
                  case 'email':
                  case 'password':
                  case 'textarea':
                    return (
                      <Field
                        key={customField.name}
                        component={CustomTextField}
                        name={customField.name}
                        label={customField.label}
                        type={customField.type}
                        validatePassword={customField.type === 'password'}
                        outlinedSize={customField.outlinedSize}
                      />
                    );
                  case 'checkbox':
                    return (
                      <Field key={customField.name} name={customField.name}>
                        {({ field, form }: FieldProps) => (
                          <CheckboxLabel
                            control={
                              <Checkbox
                                {...field}
                                checked={field.value}
                                onChange={e => {
                                  form.setFieldValue(field.name, e.target.checked);
                                }}
                                onBlur={field.onBlur}
                              />
                            }
                            label={customField.label}
                          />
                        )}
                      </Field>
                    );
                  case 'select':
                    return (
                      <Field
                        key={customField.name}
                        component={CustomSelect}
                        name={customField.name}
                        label={customField.label}
                        type={customField.type}
                        options={customField.options}
                        fullWidth
                      />
                    );
                  default:
                    return null;
                }
              })}
            </Box>
            <Box
              sx={{
                margin: 'auto',
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: `${fields.length > 1 ? 'space-between' : 'center'}`,
              }}
            >
              {fields.length > 1 && (
                <StyledButton
                  className="reset"
                  variant="contained"
                  disabled={isSubmitting}
                  onClick={() => {
                    resetForm();
                    validateForm(false);
                  }}
                >
                  {t(`button.reset`)}
                </StyledButton>
              )}

              <StyledButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                {buttonLabel}
              </StyledButton>
            </Box>
          </StyledBox>
        </StyledForm>
      )}
    </Formik>
  );
};

export default DynamicForm;
