import styled from 'styled-components'
import { Box, BoxProps } from '@mui/material'

export const StyledBox = styled(Box)<BoxProps>`
	&.MuiBox-root {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border: 10px solid #ddd;
		border-radius: 15px;
		box-sizing: border-box;

		&.active {
			border-color: #f00;
		}

		&.disabled {
			border-color: #eee;
		}

		&.bonusCircle {
			position: absolute;
			max-height: 80px;
			max-width: 80px;
			top: -40px;
			left: 50%;
			color: #ddd;
			background: #444;
			border-radius: 50%;
			transform: translateX(-50%);

			&.fired {
				color: #f00;
				border-color: #f00;
			}

			&.offline {
				color: #eee;
				border-color: #eee;
			}

			& .MuiTypography-root {
				font-weight: 900;
			}
		}
	}
`
