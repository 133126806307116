import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../redux/configureStore'
import {
	actions as bonusAction,
	selectors as bonusSelectors,
} from '../../../redux/components/bonus/'

import { StyledButton } from '../../../styleguide/Button'

import Typography from '@mui/material/Typography'

import { StyledBox } from '../style'

const ReviewBonusComponent: React.FC = () => {
	const step = 'review'
	const currentStep = useSelector(bonusSelectors.getStepper)
	const { t } = useTranslation()
	const dispatch: AppDispatch = useDispatch()

	const handleClick = () => dispatch(bonusAction.setStepper('bonus'))

	return (
		<StyledBox className={step === currentStep ? 'active' : 'disabled'}>
			<StyledBox
				className={`bonusCircle  ${step === currentStep ? 'fired' : 'offline'}`}
			>
				<Typography variant="h2">2</Typography>
			</StyledBox>
			<StyledButton
				variant="contained"
				disabled={step !== currentStep}
				onClick={handleClick}
			>
				{t(`Amazon Review`)}
			</StyledButton>
		</StyledBox>
	)
}

export default ReviewBonusComponent
