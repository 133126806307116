import React from 'react'
import Box from '@mui/material/Box'
import { SpinnerWrapper } from './style'

import { StyledCircularProgress } from '../../styleguide/CircularProgress'

const Spinner: React.FC = () => {
	return (
		<SpinnerWrapper>
			<Box sx={{ display: 'flex' }}>
				<StyledCircularProgress color="primary" />
			</Box>
		</SpinnerWrapper>
	)
}

export default Spinner
