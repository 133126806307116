// Header.tsx
import React from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Logo from '../logo/Logo'
import Navigation from '../navigation/Navigation'
import MobileMenu from '../dialog/MobileMenu'
import LanguageSelector from '../language-selector/LanguageSelector'

import { StyledAppBar } from '../../styleguide/AppNavbar'
import { HeaderContainer } from './style'

import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

const Header: React.FC = () => {
	const theme = useTheme()
	const matches = useMediaQuery(theme.breakpoints.up('sm'))

	return (
		<>
			{matches ? (
				<StyledAppBar position="static">
					<Container maxWidth="xl">
						<Toolbar>
							<Logo />
							<Box sx={{ flexGrow: 10 }} />
							<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Navigation />
							</Box>
							<LanguageSelector />
						</Toolbar>
					</Container>
				</StyledAppBar>
			) : (

					<HeaderContainer>
						<Container
							maxWidth="xl"
							sx={{ display: 'flex', alignItems: 'center' }}
						>
							<MobileMenu />
							<Box
								sx={{
									paddingTop: '5px',
									display: 'flex',
									justifyContent: 'start',
									flexGrow: 9,
								}}
							>
								<Logo />
							</Box>
						</Container>
					</HeaderContainer>
			)}
		</>
	)
}

export default Header
