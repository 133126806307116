// MyComponent.tsx
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../redux/configureStore'
import {
	actions as uiActions,
	selectors as uiSelectors,
} from '../../redux/components/ui'
import { useTranslation } from 'react-i18next'

import { TLanguage } from '../../types/languages'
import { availableLanguages } from '../../libs/languages'

import { StyledBox } from '../../styleguide/Box'

import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { SelectChangeEvent } from '@mui/material/Select'
import { Img, LanguageLabel } from './style'

import { StyledSelect } from '../../styleguide/forms/Select'

const LanguageSelector: React.FC = () => {
	const { t } = useTranslation()
	const dispatch: AppDispatch = useDispatch()
	const language = useSelector(uiSelectors.getLanguage)

	const handleChangeLanguage = (event: SelectChangeEvent<unknown>) => {
		const value = event.target.value as string
		dispatch(uiActions.setLanguage(value))
	}

	return (
		<StyledBox className="languageWrapper">
			<FormControl size="small">
				<StyledSelect
					labelId="language-select-label"
					id="language-select"
					value={language}
					dynamicbordercolor="rgba(0,0,0,0)"
					dynamicbordercolorhover="rgba(0,0,0,0)"
					dynamicbordercolorfocused="rgba(0,0,0,0)"
					onChange={event => handleChangeLanguage(event)}
				>
					{availableLanguages.map((language: TLanguage) => (
						<MenuItem key={language.id} value={language.value}>
							<LanguageLabel>
								<Img
									alt="complex"
									src={`./assets/images/flags/${language.value}.png`}
								/>
								<span>
									{t(`language.label.${language.value}`).toLocaleUpperCase()}
								</span>
							</LanguageLabel>
						</MenuItem>
					))}
				</StyledSelect>
			</FormControl>
		</StyledBox>
	)
}

export default LanguageSelector
