import React, { useState } from 'react'

import PasswordValidationComponent from '../auth/components/authConfig/PasswordValidationCriteria'

import { StyledTextField } from '../../styleguide/forms/TextField'

interface CustomTextFieldProps {
	field: any // Fornito da Formik quando usato con <Field component={...}/>
	form: any // Fornito da Formik quando usato con <Field component={...}/>
	label: string
	type: 'text' | 'email' | 'password' | 'textarea'
	validatePassword?: boolean
	outlinedField?: boolean
	outlinedSize?: string
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
	field, // { name, value, onChange, onBlur }
	form, // { touched, errors }
	label,
	type,
	validatePassword,
	outlinedSize,
	...props
  }) => {
	const [isPasswordFocused, setPasswordFocused] = useState(false);
  
	const handleFocus = () => {
	  if (type === 'password' && validatePassword) {
		setPasswordFocused(true);
	  }
	};
  
	const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
	  field.onBlur(e); // Chiama l'handler onBlur di Formik
	  if (type === 'password') {
		setPasswordFocused(false);
	  }
	};
  
	return (
	  <>
		<StyledTextField
		  {...field}
		  {...props}
		  label={label}
		  type={type}
		  error={form.touched[field.name] && !!form.errors[field.name]}
		  helperText={form.touched[field.name] && form.errors[field.name]}
		  onFocus={handleFocus}
		  onBlur={handleBlur}
		  fullWidth
		  variant="outlined"
		  size={outlinedSize}
		  multiline={type === 'textarea'}
		  rows={type === 'textarea' ? 4 : undefined}
		/>
		{isPasswordFocused && validatePassword && (
		  <PasswordValidationComponent password={field.value} />
		)}
	  </>
	);
  };
  
  export default CustomTextField;
  