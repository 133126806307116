import React, { createContext, useContext, useState, ReactNode } from 'react'

interface UserContextType {
	user: User | null
	isLoggedIn: boolean
	token: string | null
	login: (user: User, token: string) => void
	logout: () => void
}

interface User {
	username: string
	// Aggiungi qui altre proprietà necessarie
}

const UserContext = createContext<UserContextType | undefined>(undefined)

interface UserProviderProps {
	children: ReactNode
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
	const [user, setUser] = useState<User | null>(null)
	const [token, setToken] = useState<string | null>(null)
	const [isLoggedIn, setIsLoggedIn] = useState(false)

	const login = (userData: User, authToken: string) => {
		setUser(userData)
		setToken(authToken)
		setIsLoggedIn(true)
	}

	const logout = () => {
		setUser(null)
		setToken(null)
		setIsLoggedIn(false)
	}

	return (
		<UserContext.Provider value={{ user, isLoggedIn, token, login, logout }}>
			{children}
		</UserContext.Provider>
	)
}

export const useUser = (): UserContextType => {
	const context = useContext(UserContext)
	if (context === undefined) {
		throw new Error('useUser must be used within a UserProvider')
	}
	return context
}
