import { RootState } from '../../reducers'
import { TStateCounter } from '../../../schemas/counter_d'
import TYPES from './types'

const getState = (state: RootState): TStateCounter =>
	state[TYPES.PREFIX as keyof RootState] as unknown as TStateCounter

const getCounter = (state: RootState): number => getState(state).counter

const selectors = {
	getCounter,
}

export default selectors
