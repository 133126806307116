import { get } from './api'

export const getNavigation = async () => {
	try {
		const url = `${process.env.REACT_APP_NAVIGATION_PATH}`
		const result = await get(url)
		return result
	} catch (error: any) {
		// console.error("Si è verificato un errore durante il recupero dei dati utente", error.response.data.message);
		// Gestisci l'errore
		return { message: error.response.data.message }
	}
}
