import { FormConfig } from '../../../dynamic-form/DynamicForm';
import { TFunction } from 'i18next';
import { capitalize } from '../../../../utils/capitalize';
import { getLoginSchema, getSignupSchema } from './signinValidationSchema';
import { AppDispatch } from '../../../../redux/configureStore';
import { actions as authActions } from '../../../../redux/components/auth';

export const getLoginConfig = (t: TFunction, dispatch: AppDispatch): FormConfig => ({
  initialValues: { email: '', password: '', remember: false },
  validationSchema: getLoginSchema(t),
  onSubmit: (values, { setSubmitting, resetForm, validateForm }) => {
    console.log(values);
    dispatch(authActions.loginUser(values));
    setSubmitting(false);
    validateForm(false);
    resetForm();
  },
  validateOnMount: true,
  fields: [
    {
      name: 'email',
      type: 'email',
      label: capitalize(t(`form.input.login.email`)),
    },
    {
      name: 'password',
      type: 'password',
      label: capitalize(t(`form.input.login.password`)),
    },
    {
      name: 'remember',
      type: 'checkbox',
      label: capitalize(t(`form.input.login.remember`)),
    },
  ],
  buttonLabel: capitalize(t(`button.login`)),
});

export const getSigninConfig = (t: TFunction, dispatch: AppDispatch, lang: string): FormConfig => ({
  initialValues: {
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  },
  validationSchema: getSignupSchema(t),
  onSubmit: (values, { setSubmitting, resetForm, validateForm }) => {
    const data = { ...values, preferences: { language: lang } };
    dispatch(authActions.signinUser(data));
    setSubmitting(false);
    validateForm(false);
    resetForm();
  },
  validateOnMount: true,
  fields: [
    {
      name: 'username',
      type: 'text',
      label: capitalize(t(`form.input.signin.username`)),
    },
    {
      name: 'email',
      type: 'email',
      label: capitalize(t(`form.input.signin.email`)),
    },
    {
      name: 'password',
      type: 'password',
      label: capitalize(t(`form.input.signin.password`)),
    },
    {
      name: 'confirmPassword',
      type: 'password',
      label: capitalize(t(`form.input.signin.confirmPassword`)),
    },
  ],
  buttonLabel: capitalize(t(`button.signin`)),
});
