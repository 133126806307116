import { post } from './api'
import { TLoggedUser, TSignedUser, TStoredUser } from '../types/user'

export const logUser = async (userData: TLoggedUser) => {
	try {
		const url = `${process.env.REACT_APP_AUTH_PATH}`
		const data = userData
		const result = await post<TStoredUser>(url, data)
		return result
	} catch (error: any) {
		// console.error("Si è verificato un errore durante il recupero dei dati utente", error.response.data.message);
		// Gestisci l'errore
		return { message: error.response.data.message }
	}
}

export const signUser = async (userData: TSignedUser) => {
	try {
		const url = `${process.env.REACT_APP_USERS_PATH}`
		const data = userData
		const result = await post<TStoredUser>(url, data)
		console.log(result)
		return result
	} catch (error: any) {
		// console.error("Si è verificato un errore durante la creazione di un utente", error.response.data.message);
		// Gestisci l'errore
		return { message: error.response.data.message }
	}
}
