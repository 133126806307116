import styled, { keyframes } from 'styled-components';
import { Alert, AlertProps } from '@mui/material';

export const Root = styled.div`
  max-width: 100%;
  flex-grow: 1;
`;

const shrink = keyframes`
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
`;

export const Divider = styled.div<{ autoCloseDuration: number }>`
  height: 3px;
  background-color: currentColor;
  animation: ${shrink} ${(props) => props.autoCloseDuration}ms linear forwards;
`;

export const StyledAlert = styled(Alert)<AlertProps>`
  &.MuiPaper-root {
    @media (min-width: 600px) {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: start;
    }

    &.MuiAlert-root {
      width: 100%;
      background: #333;

      @media (min-width: 600px) {
        margin: auto;
        width: 50%;
      }

      & .MuiAlert-message {
        font-weight: 500;
        width: 100%;

        & .MuiTypography-root {
          &.MuiAlertTitle-root {
            font-weight: 600;
            text-align: left;
            text-transform: uppercase;
          }

          &.MuiTypography-body2 {
            padding: 5px;
            background: #222;
            border-radius: 10px;

            @media (min-width: 600px) {
              padding: 20px;
            }
          }
        }
      }

      &.MuiAlert-standardInfo {
        color: cyan;

        & .MuiSvgIcon-root {
          fill: cyan;
        }
      }

      &.MuiAlert-standardSuccess {
        color: #0f0;

        & .MuiSvgIcon-root {
          fill: #0f0;
        }
      }

      &.MuiAlert-standardWarning {
        color: orange;

        & .MuiSvgIcon-root {
          fill: orange;
        }
      }

      &.MuiAlert-standardError {
        color: #f00;

        & .MuiSvgIcon-root {
          fill: #f00;
        }
      }
    }
  }
`;
