import TYPES from './types'
import { TStepper, TSetStepper } from '../../../schemas/bonus_d'

const setStepper = (step: TStepper): TSetStepper => ({
	type: TYPES.SET_STEPPER,
	payload: step,
})

const actions = {
	setStepper,
}

export default actions
