import React from 'react'
import { FormControlLabelProps } from '@mui/material'

import { StyledFormControlLabel } from './style'

interface IFormControlLabelProps extends FormControlLabelProps {}

const CheckboxLabel: React.FC<IFormControlLabelProps> = ({
	control,
	label,
}) => {
	return <StyledFormControlLabel control={control} label={label} />
}

export default CheckboxLabel
