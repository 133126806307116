// sagas.js
import { call, put, takeLatest } from 'redux-saga/effects'
import {
	TFaqsSection,
	TFaqsResponse
} from '../../../types/faqs'
import { getFaqs } from '../../../api/faqsApi'
import faqsActions from './actions'
import { actions as uiActions } from '../ui'
import TYPES from './types'

function* handleFaqs() {
	try {
		
		const result: TFaqsResponse = yield call(getFaqs)
		if (result?.data) {
			const navigationItems: TFaqsSection[] = Object.values(result.data)
			yield put(faqsActions.setFaqs(navigationItems))
		}
		yield put(uiActions.setMessage(result.message))
	} catch (error: any) {
		yield put(uiActions.setMessage(error.message))
		console.log(error)
	}
}

// Watchers
export default function* watchFaqs() {
	yield takeLatest(TYPES.GET_FAQS, handleFaqs)

}
