const PREFIX = 'BONUS'
const CHECK_AUTH = `${PREFIX}.CHECK_AUTH`
const SET_TOKEN = `${PREFIX}.SET_TOKEN`
const DELETE_TOKEN = `${PREFIX}.DELETE_TOKEN`
const SET_STEPPER = `${PREFIX}SET_STEPPER`

const TYPES = {
	PREFIX,
	CHECK_AUTH,
	SET_TOKEN,
	DELETE_TOKEN,
	SET_STEPPER,
}

export default TYPES
