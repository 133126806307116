import styled from 'styled-components'

export const LanguageLabel = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	& span {
		font-size: 12px;
		font-weight: 900;
		color: rgba(0,0,0,0.3);
	}
`

export const Img = styled('img')({
	margin: 'auto',
	paddingRight: '5px',
	display: 'inline',
	width: '15px',
	height: '15px',
})
