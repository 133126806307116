import TYPES from './types'
import { TGetNavigation, TSetNavigation } from '../../../schemas/navigation_d'
import {TNavbarItem} from '../../../types/navigation'

const getNavbar = (): TGetNavigation => ({
	type: TYPES.GET_NAVBAR,
})

const setNavbar = (navbarItems: TNavbarItem[]): TSetNavigation => ({
	type: TYPES.SET_NAVBAR,
	payload: navbarItems
})


const actions = {
	getNavbar,
	setNavbar,
}

export default actions
