import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function useBookId(path: string): string {
	const [bookId, setBookId] = useState('')
	const location = useLocation()

	useEffect(() => {
		const bookIdPath: string = location.pathname.replace(`/${path}/`, '')
		console.log(path)
		setBookId(bookIdPath)
	}, [location.pathname, path])

	return bookId
}

export default useBookId
