import styled from 'styled-components'
import { Form, FormikFormProps } from 'formik'

export const StyledForm = styled(Form)<FormikFormProps>`
	min-width: 100%;

	@media (min-width: 600px) {
		min-width: 500px;
	}
`
