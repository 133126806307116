import React from 'react'
import { Link } from 'react-router-dom'
import { LogoWrapper } from './style'

const Logo = () => {
	return (
		<Link to="/">
			<LogoWrapper
				src={'./assets/images/logo/d&p.svg'}
				alt="Draw & Play Logo"
			/>
		</Link>
	)
}

export default Logo
