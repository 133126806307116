import React from 'react'

import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import YouTubeIcon from '@mui/icons-material/YouTube'
import XIcon from '@mui/icons-material/X'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

import { FooterSocialIconsContainer } from '../style'

const FooterSocialMediaNavBar: React.FC = () => {
	return (
		<>
			<FooterSocialIconsContainer>
				<Stack>
					<IconButton aria-label="Facebook" size="large">
						<FacebookIcon fontSize="large" />
					</IconButton>
					<IconButton aria-label="Instagram" size="large">
						<InstagramIcon fontSize="large" />
					</IconButton>
					<IconButton aria-label="Youtube" size="large">
						<YouTubeIcon fontSize="large" />
					</IconButton>
					<IconButton aria-label="Linkedin" size="large">
						<LinkedInIcon fontSize="large" />
					</IconButton>
					<IconButton aria-label="X" size="large">
						<XIcon fontSize="large" />
					</IconButton>
				</Stack>
			</FooterSocialIconsContainer>
		</>
	)
}

export default FooterSocialMediaNavBar
