import React from 'react'
import { useTranslation } from 'react-i18next'

import { capitalize } from '../../../../utils/capitalize'

import Typography from '@mui/material/Typography'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

interface IPasswordValidationCriteria {
	password: string
}

// Componente riutilizzabile per mostrare i criteri di validazione della password
const PasswordValidationCriteria: React.FC<IPasswordValidationCriteria> = ({
	password,
}) => {
	const { t } = useTranslation()

	const passwordCriteria = {
		minLength: {
			rule: /.{8,20}/,
			message: capitalize(t(`form.validation.minLength`)),
		},
		lowercase: {
			rule: /[a-z]/,
			message: capitalize(t(`form.validation.lowercase`)),
		},
		uppercase: {
			rule: /[A-Z]/,
			message: capitalize(t(`form.validation.uppercase`)),
		},
		number: {
			rule: /[0-9]/,
			message: capitalize(t(`form.validation.number`)),
		},
		specialChar: {
			rule: /[\^$*.[\]{}()?!@#%&,~`_-]/,
			message: capitalize(t(`form.validation.specialChar`)),
		},
	}

	return (
		<div>
			{Object.entries(passwordCriteria).map(([key, { rule, message }]) => (
				<Typography
					key={key}
					style={{
						color: rule.test(password) ? 'green' : 'red',
						fontSize: '12px',
						textAlign: 'left',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{rule.test(password) ? (
						<CheckIcon style={{ color: 'green', marginRight: '4px' }} />
					) : (
						<CloseIcon style={{ color: 'red', marginRight: '4px' }} />
					)}
					{message}
				</Typography>
			))}
		</div>
	)
}

export default PasswordValidationCriteria
