import React, { useEffect } from 'react';
import { TMessage } from '../../types/ui';
import { useDispatch, useSelector } from 'react-redux';
import {
  actions as uiAction,
  selectors as uiSelectors,
} from '../../redux/components/ui';
import { AlertTitle, Typography, Box } from '@mui/material';
import { StyledAlert, Divider } from './style';

interface MessagesProps {
  sx?: object;
  autoCloseDuration?: number;
}

const Messages: React.FC<MessagesProps> = ({ sx = {}, autoCloseDuration = 5000 }) => {
  const dispatch = useDispatch();
  const message: TMessage | undefined = useSelector(uiSelectors.getMessage);

  useEffect(() => {
    if (message && message.body && message.type) {
      const timer = setTimeout(() => {
        dispatch(uiAction.deleteMessage());
      }, autoCloseDuration);
      return () => clearTimeout(timer);
    }
  }, [message, dispatch, autoCloseDuration]);

  const { body, type } = message || {};

  return (
    <>
      {type && body && (
        <StyledAlert
          severity={type}
          onClose={() => dispatch(uiAction.deleteMessage())}
          aria-live="assertive"
          aria-atomic="true"
        >
          <AlertTitle>{type}</AlertTitle>
          <Divider autoCloseDuration={autoCloseDuration} />
          <Typography variant="body2" className={type}>{body}</Typography>
        </StyledAlert>
      )}
    </>
  );
}

export default Messages;
