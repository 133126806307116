import styled from 'styled-components'

export const FooterWrapper = styled.footer`
	background-color: #ddd;

	& .MuiContainer-root {
		display: flex;
		flex-direction: column;
		-webkit-box-align: center;
		align-items: center;

		@media (min-width: 0px) {
			gap: 32px;
			padding-top: 32px;
			padding-bottom: 32px;
			text-align: left;
		}

		@media (min-width: 600px) {
			text-align: center;
		}

		@media (min-width: 900px) {
			text-align: left;
		}
	}
`

export const FooterContainer = styled.div`
	@media (min-width: 600px) {
		width: 100%;
	}

	& .MuiBox-root {
		display: flex;
		width: 100%;
		-webkit-box-pack: justify;
		justify-content: space-between;

		@media (min-width: 0px) {
			flex-direction: column;
		}

		@media (min-width: 600px) {
			flex-direction: row;
		}
	}
`

export const FooterLogoAndNewsLetterWrapper = styled.div`
	& .MuiBox-root {
		display: flex;
		flex-direction: column;

		@media (min-width: 0px) {
			min-width: 100%;
		}

		@media (min-width: 600px) {
			min-width: 60%;
		}
	}
`

export const FooterLogoAndNewsLetterContainer = styled.div`
	& .MuiBox-root {
		width: 100%;

		@media (min-width: 600px) {
			width: 60%;
		}
	}
`

export const FooterLogoImg = styled.img`
	width: 100%;
	height: auto;

	@media (min-width: 1200px) {
		width: 400px;
	}
`

export const FooterNavigationContainer = styled.div`
	@media (max-width: 750px) {
		display: none;
	}

	@media (min-width: 751px) {
		min-width: 500px;
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		align-items: start;
	}

	& .MuiBox-root {
		max-width: fit-content;
		flex-direction: column;
		gap: 8px;

		@media (min-width: 0px) {
			display: none;
		}

		@media (min-width: 600px) {
			display: flex;
		}
	}
`
export const FooterNavigationContainerMobile = styled.div`
	@media (max-width: 750px) {
		margin-top: 30px;
		min-width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: start;
		gap: 16px;
	}

	& .MuiBox-root {
		max-width: fit-content;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: left;
		align-items: left;
		gap: 8px;

		@media (max-width: 750px) {
			display: flex;
		}

		@media (min-width: 751px) {
			display: none;
		}

		& .MuiBox-root {
			max-width: fit-content;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: left;
			align-items: center;
			gap: 8px;
	}

	@media (min-width: 751px) {
		display: none;
	}
`

export const FooterBottomContainer = styled.div`
	width: 100%;
	@media (min-width: 600px) {
		width: 100%;
	}

	& .MuiBox-root {
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
		width: 100%;
		border-top: 1px solid rgba(76, 89, 103, 0.3);
		border-right-color: rgba(76, 89, 103, 0.3);
		border-bottom-color: rgba(76, 89, 103, 0.3);
		border-left-color: rgba(76, 89, 103, 0.3);

		@media (min-width: 0px) {
			padding-top: 32px;
			flex-direction: column-reverse;
		}

		@media (min-width: 600px) {
			flex-direction: row;
		}
	}
`

export const FooterLegalContainer = styled.div`
	padding-top: 16px;
	display: flex;
	-webkit-box-pack: justify;
	justify-content: flex-start;

	@media (min-width: 600px) {
		padding-top: 0px;
	}
`

export const FooterCopyContainer = styled.div`
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
	justify-content: center;
	color: #fff;
	background: #000;
`

export const FooterBottomLink = styled.div`
	white-space: nowrap;

	& .MuiTypography-root {
		margin: 0px;
		font-style: inherit;
		font-variant: inherit;
		font-stretch: inherit;
		font-size: 15px;
		line-height: inherit;
		font-family: inherit;
		font-optical-sizing: inherit;
		font-kerning: inherit;
		font-feature-settings: inherit;
		font-variation-settings: inherit;
		font-weight: 500;
		position: relative;
		text-decoration: none;
		color: rgb(148, 166, 184);
	}
`

export const FooterBottomDot = styled.div`
	& .MuiTypography-root {
		margin: 0px 4px;
		font-weight: 400;
		font-size: 15px;
		font-family: Inter, 'sans-serif';
		line-height: 1.5;
		display: inline;
		opacity: 0.5;
	}
`

export const FooterBottomCopyright = styled.div`
	& .MuiTypography-root {
		margin: 8px 0px 0px;
		font-weight: 400;
		font-size: 14px;
		font-family: Inter, 'sans-serif';
		line-height: 1.43;
		color: rgb(148, 166, 184);
	}
`

export const FooterSocialIconsContainer = styled.div`
	& .MuiStack-root {
		display: flex;
		flex-direction: row;
		gap: 8px;
		justify-content: space-between;
		color: rgb(148, 166, 184);

		@media (min-width: 0px) {
			padding-bottom: 32px;
			border-bottom: 1px solid rgba(76, 89, 103, 0.3);
		}

		@media (min-width: 600px) {
			justify-content: flex-start;
			padding-bottom: 0px;
			border-bottom: 0px;
		}

		& .MuiIconButton-root {
			display: inline-flex;
			-webkit-box-align: center;
			align-items: center;
			-webkit-box-pack: center;
			justify-content: center;
			position: relative;
			-webkit-tap-highlight-color: transparent;
			background-color: transparent;
			outline: 0px;
			border: 0px;
			margin: 0px;
			cursor: pointer;
			user-select: none;
			vertical-align: middle;
			appearance: none;
			text-decoration: none;
			box-sizing: border-box;
			text-align: center;
			flex: 0 0 auto;
			font-size: 1.5rem;
			padding: 8px;
			border-radius: 50%;
			overflow: visible;
			transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			color: #94a6b8;
			align-self: center;
		}

		& .MuiIconButton-root:hover {
			color: #fff;
			background-color: rgba(255, 0, 0, 1);
		}
	}
`
