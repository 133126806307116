import TYPES from './types'
import {
	TSetLanguage,
	TSetWindowWidth,
	TSetDeviceType,
	TDeviceType,
	TSetToggleView,
	TSetLoading,
	TSetMessage,
	TDeleteMessage,
} from '../../../schemas/ui_d'
import { TMessage } from '../../../types/ui'

const setWindowWidth = (windowWidthsize: number): TSetWindowWidth => ({
	type: TYPES.SET_WINDOW_WIDTH,
	payload: windowWidthsize,
})

const setLanguage = (language: string): TSetLanguage => ({
	type: TYPES.SET_LANGUAGE,
	payload: language,
})

const setDeviceType = (deviceType: TDeviceType): TSetDeviceType => ({
	type: TYPES.SET_DEVICE_TYPE,
	payload: deviceType,
})

const setToggleView = (): TSetToggleView => ({
	type: TYPES.TOGGLE_VIEW,
})

const setLoading = (loaderStatus: boolean): TSetLoading => ({
	type: TYPES.SET_LOADING,
	payload: loaderStatus,
})

const setMessage = (message: TMessage): TSetMessage => ({
	type: TYPES.SET_MESSAGE,
	payload: message,
})

const deleteMessage = (): TDeleteMessage => ({
	type: TYPES.DELETE_MESSAGE,
})

const actions = {
	setWindowWidth,
	setLanguage,
	setDeviceType,
	setToggleView,
	setLoading,
	setMessage,
	deleteMessage,
}

export default actions
