import React from 'react'
// import { useTheme } from '@mui/material/styles'
// import useMediaQuery from '@mui/material/useMediaQuery'
import { useSelector } from 'react-redux'
import { selectors as uiSelectors } from '../../redux/components/ui'

const Ui: React.FC = () => {
	// const theme = useTheme()
	// const matches = useMediaQuery(theme.breakpoints.up('sm'))
	const windowWidth = useSelector(uiSelectors.getWindowWidth)

	return (
	<>
	{windowWidth}
	</>
	)
}

export default Ui
