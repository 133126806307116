import { ThemeOptions, createTheme } from '@mui/material/styles'

import { customPalette } from './themePalette'

declare module '@mui/material' {
	interface Color {
		50: string
		100: string
		200: string
		300: string
		400: string
		500: string
		550: string
		600: string
		700: string
		800: string
		900: string
		A100: string
		A200: string
		A400: string
		A700: string
	}
}

declare module '@mui/material/styles' {
	interface PaletteColor {
		[key: number | string]: string
	}

	interface Palette {
		avatar: Palette['primary']
		spacer: Palette['primary']
		aquamarine: Palette['primary']
		greyCustom: Palette['primary']
		violet: Palette['primary']
		titles: Palette['primary']
	}

	interface PaletteOptions {
		avatar: PaletteOptions['primary']
		spacer: PaletteOptions['primary']
	}
}

// To add custom variables to theme with TS
// https://material-ui.com/guides/typescript/#customization-of-theme
const customTheme: ThemeOptions = {
	// components: themeComponents,
	typography: {
		fontFamily: 'Neue Haas Grotesk Text Std, Arial, sans-serif',
		fontSizes: {
			XXS: '0.4rem',
			XS: '0.6rem',
			S: '0.8rem',
			M: '1rem',
			L: '1.2rem',
			XL: '1.4rem',
			XXL: '1.6rem',
		},
	},
	shape: {
		borderRadius: 5,
		borderThickness: 1,
	},
	palette: customPalette,
	zIndex: {
		mobileStepper: 1000,
		speedDial: 1050,
		pageElements: 1068,
		header: 1101,
		appBar: 1100,
		drawer: 1200,
		autocompletePopper: 1250,
		snackbar: 1400,
		tooltip: 1450,
		call: 1470,
		modal: 1500,
		autocompleteModalPopper: 1501,
		lightbox: 1600,
	},
} as ThemeOptions

const theme = createTheme(customTheme)

theme.typography.h1 = {
	fontSize: '1.6rem',
	fontWeight: 400,
	textTransform: 'capitalize',
}

theme.typography.h2 = {
	fontSize: '1.3rem',
	fontWeight: 400,
	textTransform: 'capitalize',
}

export const sizings = {
	mdLarge: 850,
	mdSmall: 740,
	drawer: 240,
	drawerMenu: 216,
	drawerHandle: 24,
	scrollbar: 7,
}

export default theme
