import React from 'react'

import Box from '@mui/material/Box'

import { FooterLogoImg } from '../style'

const FooterLogo: React.FC = () => {
	return (
		<>
			<Box>
				<FooterLogoImg
					src={'./assets/images/logo/d&p.png'}
					alt="Draw & Play Logo"
				/>
			</Box>
		</>
	)
}

export default FooterLogo
