import { RootState } from '../../reducers'
import { TStateFaqs } from '../../../schemas/faqs_d'
import { TFaqsSection } from '../../../types/faqs'
import TYPES from './types'

const getState = (state: RootState): TStateFaqs =>
	state[TYPES.PREFIX as keyof RootState] as unknown as TStateFaqs

const getFaqs = (state: RootState): TFaqsSection[] => getState(state).faqs

const selectors = {
	getFaqs,
}

export default selectors
