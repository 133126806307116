import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DynamicForm from '../../dynamic-form/DynamicForm';
import { getSigninConfig } from './authConfig/signinFormConfig';
import { AppDispatch } from '../../../redux/configureStore';
import { selectors as uiSelectors } from '../../../redux/components/ui';

const Signin: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const lang = useSelector(uiSelectors.getLanguage);

  const signinConfig = getSigninConfig(t, dispatch, lang);

  return <DynamicForm config={signinConfig} />;
};

export default Signin;
