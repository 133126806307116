import styled from 'styled-components'
import { AppBar, AppBarProps } from '@mui/material'

export const StyledAppBar = styled(AppBar)<AppBarProps>`
	&.MuiAppBar-colorPrimary {
		@media (min-width: 600px) {
			background: #fff;
		}
	}
`
//fieldset.MuiOutlinedInput-notchedOutline