import { FormConfig } from '../../../dynamic-form/DynamicForm';
import { TFunction  } from 'i18next';
import { capitalize } from '../../../../utils/capitalize';
import { getLoginSchema } from './loginValidationSchema';
import { AppDispatch } from '../../../../redux/configureStore';
import { actions as authActions } from '../../../../redux/components/auth';

export const getLoginConfig = (t: TFunction, dispatch: AppDispatch): FormConfig => ({
  initialValues: { email: '', password: '', remember: false },
  validationSchema: getLoginSchema(t),
  onSubmit: (values, { setSubmitting, resetForm, validateForm }) => {
    console.log(values);
    dispatch(authActions.loginUser(values));
    setSubmitting(false);
    validateForm(false);
    resetForm();
  },
  validateOnMount: true,
  fields: [
    {
      name: 'email',
      type: 'email',
      label: capitalize(t(`form.input.login.email`)),
    },
    {
      name: 'password',
      type: 'password',
      label: capitalize(t(`form.input.login.password`)),
    },
    {
      name: 'remember',
      type: 'checkbox',
      label: capitalize(t(`form.input.login.remember`)),
    },
  ],
  buttonLabel: capitalize(t(`button.login`)),
});
