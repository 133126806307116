import {
	TStateAuth,
	TAuthActionTypes,
	TSetToken,
	TAuthSuccess,
	TAuthFailure,
} from '../../../schemas/auth_d'
import { TStoredUser } from '../../../types/user'
import TYPES from './types'

const emptyUser: TStoredUser = {
	username: '',
	email: '',
	role: '',
}

const initialState: TStateAuth = {
	token: '',
	user: emptyUser,
	isAuthenticated: false,
	error: null,
}

const authReducer = (
	state: TStateAuth = initialState,
	action: TAuthActionTypes,
): TStateAuth => {
	switch (action.type) {
		case TYPES.SET_TOKEN:
			return {
				...state,
				token: (action as TSetToken).payload,
			}
		case TYPES.DELETE_TOKEN:
			return {
				...state,
				token: '',
			}
		case TYPES.LOGIN_SUCCESS:
		case TYPES.SIGNIN_SUCCESS:
			return {
				...state,
				isAuthenticated: true,
				user: (action as TAuthSuccess).payload,
			}
		case TYPES.LOGIN_FAILURE:
		case TYPES.SIGNIN_FAILURE:
			return {
				...state,
				isAuthenticated: false,
				error: (action as TAuthFailure).payload,
			}
		default:
			return state
	}
}

export default authReducer
