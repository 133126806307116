import axios, { AxiosRequestConfig } from 'axios'

// Crea un'istanza di Axios con configurazioni predefinite
const apiInstance = axios.create({
	baseURL: `${process.env.REACT_APP_BACKEND_REAL_PATH}${process.env.REACT_APP_API_PATH}`,
	headers: {
		'Content-Type': 'application/json',
	},
})

// Esempio di interceptor per aggiungere un token JWT agli headers di ogni richiesta
apiInstance.interceptors.request.use(
	config => {
		const token = localStorage.getItem('token')
		if (token) {
			config.headers.Authorization = `Bearer ${token}`
		}
		return config
	},
	error => {
		return Promise.reject(error)
	},
)

// Interceptor per la gestione centralizzata degli errori
apiInstance.interceptors.response.use(
	response => response,
	error => {
		// Qui puoi decidere come gestire gli errori globalmente
		// console.error("Errore nell'API:", error.response);
		return Promise.reject(error)
	},
)

async function apiCall<T = any>(config: AxiosRequestConfig): Promise<T> {
	try {
		const response = await apiInstance(config)
		return response.data
	} catch (error) {
		throw error
	}
}

export async function get<T = any>(
	url: string,
	config?: AxiosRequestConfig,
): Promise<T> {
	return apiCall<T>({ ...config, url, method: 'GET' })
}

export async function post<T = any>(
	url: string,
	data?: any,
	config?: AxiosRequestConfig,
): Promise<T> {
	return apiCall<T>({ ...config, url, method: 'POST', data })
}

export async function put<T = any>(
	url: string,
	data?: any,
	config?: AxiosRequestConfig,
): Promise<T> {
	return apiCall<T>({ ...config, url, method: 'PUT', data })
}

export async function del<T = any>(
	url: string,
	config?: AxiosRequestConfig,
): Promise<T> {
	return apiCall<T>({ ...config, url, method: 'DELETE' })
}
