import { TLanguages } from '../types/languages'

export const availableLanguages: TLanguages = [
	{
		id: 1,
		value: 'en',
	},
	{
		id: 2,
		value: 'es',
	},
	{
		id: 3,
		value: 'it',
	},
]
