import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../redux/configureStore'
import {
	actions as counterAction,
	selectors as counterSelectors,
} from '../../redux/components/counter/'

interface ProvaProps {
	myMethod: (name: string) => void
}

const Prova: React.FC<ProvaProps> = ({ myMethod }) => {
	const dispatch: AppDispatch = useDispatch()
	const counter: number = useSelector(counterSelectors.getCounter)

	const handleClick = (): void => {
		myMethod('mario')
	}

	const handleIncrement = (): void => {
		dispatch(counterAction.incrementCounter())
	}
	const handleDecrement = (): void => {
		dispatch(counterAction.decrementCounter())
	}

	const handleReset = (): void => {
		dispatch(counterAction.resetCounter())
	}

	return (
		<>
			<div>Prova</div>
			<div onClick={handleClick}>CLICCA QUI</div>
			<div onClick={handleIncrement}>INCREMENTA</div>
			<div onClick={handleDecrement}>DECREMENTA</div>
			<div onClick={handleReset}>RESETTA</div>
			<div>COUNTER: {counter}</div>
		</>
	)
}

export default Prova
