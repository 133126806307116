import React from 'react'
import { useTranslation } from 'react-i18next'
import useCustomLocation from '../hooks/useCustomLocation'

import Hero from '../components/hero/Hero'
import Prova from '../components/prova/Prova'
import Faqs from '../components/faqs/Faqs'
import MobileMenu from '../components/dialog/MobileMenu'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

const Home: React.FC = () => {
	const { t } = useTranslation()
	const path = useCustomLocation()

	const myFunction = (name: string): void => {
		console.log(name)
	}

	return (
		<>
		    <Hero />
			<Container sx={{marginBottom: '30px'}}>
				<h1 className="pageTitle">{t(`pages.${path}.title`)}</h1>
				<Prova myMethod={myFunction} />

			</Container>
			<Container sx={{marginTop: '30px', marginBottom: '50px'}}>
				<Box sx={{margin: "40px"}} >
					<MobileMenu />
				</Box>
				<Faqs />
			</Container>
		</>
	)
}

export default Home
