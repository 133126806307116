import styled from 'styled-components'

export const StyledCPContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 40px;
`

export const StyledCPCircle = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 200px;
	height: 200px;
	border-radius: 50%;

	&::before {
		content: '';
		position: absolute;
		inset: 5px;
		border-radius: 50%;
		background-color: #222;
		opacity: 0.8;
	}

	&::after {
		content: '';
		position: absolute;
		width: 120px;
		height: 120px;
		border: 15px solid #4d4c51;
		border-radius: 50%;
		background-color: #333;
		box-shadow:
			inset 0 5px 10px rgba(0, 0, 0, 0.25),
			0 10px 10px rgba(0, 0, 0, 0.75),
			0 -2px 2px rgba(255, 255, 5, 0.5),
			inset 0 4px 2px rgba(0, 0, 0, 0.25),
			inset 0 -2px 2px rgba(255, 255, 5, 0.5);
	}

	& .number {
		position: relative;
		z-index: 10;
		font-family: Verdana, Geneva, Tahoma, sans-serif;
		line-height: 1em;
		font-size: 2em;

		& span {
			font-family: Verdana, Geneva, Tahoma, sans-serif;
			font-weight: 500;
			font-size: 0.5em;
		}
	}

	& h4 {
		position: relative;
		color: #fff;
		z-index: 10;
		font-family: Verdana, Geneva, Tahoma, sans-serif;
		font-weight: 500;
		font-size: 0.6em;
	}
`
