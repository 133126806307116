import React from 'react'

import BonusComponent from '../components/bonus/BonusComponent'

const Auth: React.FC = () => {
	return (
		<>
			<BonusComponent />
		</>
	)
}

export default Auth
