import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../redux/configureStore'
import {
	actions as uiActions,
	selectors as uiSelectors,
} from '../redux/components/ui'
import { MOBILE_BREAKPOINT, DESKTOP_BREAKPOINT } from '../libs/navigation'

// Definisce un hook personalizzato per ottenere la larghezza della finestra del browser
function useWindowWidth(): number {
	const dispatch = useDispatch<AppDispatch>()
	const width = useSelector(uiSelectors.getWindowWidth)

	useEffect(() => {
		const handleResize = () => {
			const innerWidth = window.innerWidth
			let deviceType: 'mobile' | 'tablet' | 'desktop'

			if (innerWidth < MOBILE_BREAKPOINT) {
				deviceType = 'mobile'
			} else if (
				innerWidth >= MOBILE_BREAKPOINT &&
				innerWidth < DESKTOP_BREAKPOINT
			) {
				deviceType = 'tablet'
			} else {
				deviceType = 'desktop'
			}

			dispatch(uiActions.setWindowWidth(innerWidth))
			dispatch(uiActions.setDeviceType(deviceType))
		}

		window.addEventListener('resize', handleResize)
		handleResize()

		return () => window.removeEventListener('resize', handleResize)
	}, [dispatch])

	return width
}

export default useWindowWidth
