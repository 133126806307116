// middlewares.ts
import { Middleware } from 'redux'
import i18n from '../../i18n/i18n'
import TYPES from '../components/ui/types'

export const languageMiddleware: Middleware =
	store => next => (action: any) => {
		if (action.type === TYPES.SET_LANGUAGE) {
			const newLanguage = action.payload
			i18n.changeLanguage(newLanguage)
		}
		return next(action)
	}
