import { combineReducers } from '@reduxjs/toolkit'
import counterReducer, { types as counterTypes } from '../components/counter'
import uiReducer, { types as uiTypes } from '../components/ui'
import authReducer, { types as authTypes } from '../components/auth/'
import bonusReducer, { types as bonusTypes } from '../components/bonus'
import navigationReducer, {types as navigationTypes} from '../components/navigation'
import faqsReducer, { types as faqsTypes } from '../components/faqs'


const rootReducer = combineReducers({
	[counterTypes.PREFIX]: counterReducer,
	[uiTypes.PREFIX]: uiReducer,
	[authTypes.PREFIX]: authReducer,
	[bonusTypes.PREFIX]: bonusReducer,
	[navigationTypes.PREFIX]: navigationReducer,
	[faqsTypes.PREFIX]: faqsReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
