const PREFIX = 'UI'
const SET_WINDOW_WIDTH = `${PREFIX}.SET_WINDOW_WIDTH`
const SET_LANGUAGE = `${PREFIX}.SET_LANGUAGE`
const SET_DEVICE_TYPE = `${PREFIX}.SET_DEVICE_TYPE`
const TOGGLE_VIEW = `${PREFIX}.TOGGLE_VIEW`
const SET_LOADING = `${PREFIX}.SET_LOADING`
const SET_MESSAGE = `${PREFIX}.SET_MESSAGE`
const DELETE_MESSAGE = `${PREFIX}.DELETE_MESSAGE`

const TYPES = {
	PREFIX,
	SET_WINDOW_WIDTH,
	SET_LANGUAGE,
	SET_DEVICE_TYPE,
	TOGGLE_VIEW,
	SET_LOADING,
	SET_MESSAGE,
	DELETE_MESSAGE,
}

export default TYPES
