import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { tutorialSteps } from '../../libs/hero';
import { Paper, useTheme } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Root, Img, ImgContainer, PrevButton, NextButton, LinkText } from './style';

const Hero: React.FC = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState<number>(0);
  const maxSteps = tutorialSteps.length;

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handleNext = () => {
    setActiveStep((prevStep) => (prevStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => (prevStep - 1 + maxSteps) % maxSteps);
  };

  return (
    <Root>
      <Paper square elevation={0}>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {tutorialSteps.map((step, index) => (
            <ImgContainer key={step.label} style={{ display: activeStep === index ? 'block' : 'none' }}>
              <Img src={step.imgPath} alt={step.label} />
              <LinkText to={step.link}>Dummy Text</LinkText>
              <PrevButton onClick={handleBack}>
                <KeyboardArrowLeft fontSize="inherit" />
              </PrevButton>
              <NextButton onClick={handleNext}>
                <KeyboardArrowRight fontSize="inherit" />
              </NextButton>
            </ImgContainer>
          ))}
        </SwipeableViews>
      </Paper>
    </Root>
  );
};

export default Hero;
