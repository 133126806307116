import React from 'react'
import { useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography'

const BookCoverBonusComponent: React.FC = () => {
	const { t } = useTranslation()

	return (
		<>
			<Typography variant="h2" gutterBottom={true}>
				{t(`book title`)}
			</Typography>
		</>
	)
}

export default BookCoverBonusComponent
