import React from 'react'
import useCustomLocation from '../hooks/useCustomLocation'
import { useTranslation } from 'react-i18next'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

import Card from '../components/card/Card'

const Books: React.FC = () => {
	const { t } = useTranslation()
	const path = useCustomLocation()

	return (
		<>
			<Container>
				<h1 className="pageTitle">{t(`pages.${path}.title`)}</h1>
				<Box
					component="section"
					sx={{
						mb: '36px',
						height: '100%',
						width: '100%',
						display: 'flex',
						justifyContent: 'start',
						alignItems: 'center',
						flexWrap: 'wrap',
						gap: '20px',
					}}
				>
					<Card />
					<Card />
					<Card />
					<Card />
					<Card />
					<Card />
					<Card />
					<Card />
					<Card />
					<Card />
				</Box>
			</Container>
		</>
	)
}

export default Books
