import React from 'react'
import { useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography'

import {
	FooterBottomCopyright,
} from '../style'

const FooterCopy: React.FC = () => {
	const { t } = useTranslation()
	return (
		<>
			<FooterBottomCopyright>
				<Typography variant="body2">{t(`footer.copyright`)}</Typography>
			</FooterBottomCopyright>
		</>
	)
}

export default FooterCopy
