const PREFIX = 'NAVIGATION'
const GET_NAVBAR = `${PREFIX}.GET_NAVBAR`
const SET_NAVBAR = `${PREFIX}.SET_NAVBAR`


const TYPES = {
	PREFIX,
	GET_NAVBAR,
	SET_NAVBAR,

}

export default TYPES
