import React from 'react'
import useCustomLocation from '../hooks/useCustomLocation'
import useBookId from '../hooks/useBookId'
import { useTranslation } from 'react-i18next'

import Container from '@mui/material/Container'

const Book: React.FC = () => {
	const { t } = useTranslation()
	const path = useCustomLocation()
	const bookId = useBookId(path)

	return (
		<>
			<Container>
				<h1 className="pageTitle">{t(`pages.${path}.title`)}</h1>
				<div>Book ID: {bookId}</div>
			</Container>
		</>
	)
}

export default Book
